import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import usePracticeData from "../../../hooks/usePracticeData";
import Header from "./Header";
import RoleSelector from "./RoleSelector";
import CourseTable from "./CourseTable";

const PracticeList = () => {
  const { collection, role, course } = useParams();
  const navigate = useNavigate();

  const {
    initialRole,
    selectedRole,
    practiceData,
    showSpinner,
    selectedCourse,
    setSelectedCourse,
    handleRoleChange,
  } = usePracticeData();

  useEffect(() => {
    if (course) {
      setSelectedCourse(course);
    }
  }, [course, setSelectedCourse]);

  useEffect(() => {
    if (collection) {
      console.log(`Handling role change: ${collection}`);
      handleRoleChange(collection);
    }
  }, [collection, handleRoleChange]);

  useEffect(() => {
    if (selectedCourse && role) {
      console.log(
        `Navigating to /practice/${role.toLowerCase()}/${selectedCourse.toLowerCase()}`
      );
      navigate(
        `/practice/${role.toLowerCase()}/${selectedCourse.toLowerCase()}`,
        { replace: true }
      );
    }
  }, [selectedCourse, role, navigate]);

  if (showSpinner) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        Loading...
      </div>
    );
  }

  const dataArray = Array.isArray(practiceData.data) ? practiceData.data : [];

  let matchedData = dataArray.find((data) =>
    data.type.toLowerCase().includes(selectedRole.toLowerCase())
  );

  matchedData = matchedData
    ? {
        ...matchedData,
        courses: [...matchedData.courses].sort((a, b) =>
          a.courseName.localeCompare(b.courseName)
        ),
      }
    : null;

  const handleCourseSelect = (courseName) => {
    setSelectedCourse(courseName);
  };

  return (
    <div className="min-h-screen cursor-default bg-zinc-900 p-8">
      <Header
        initialRole={initialRole}
        selectedRole={selectedRole}
        practiceData={practiceData}
        handleRoleChange={handleRoleChange}
      />

      {initialRole !== "{Your Role}" && (
        <RoleSelector
          matchedData={matchedData}
          selectedCourse={selectedCourse}
          setSelectedCourse={handleCourseSelect}
        />
      )}

      {matchedData && (
        <div>
          {selectedCourse
            ? matchedData.courses
                .filter(
                  (course) =>
                    course.courseName.toLowerCase() ===
                    selectedCourse.toLowerCase()
                )
                .map((course, courseIndex) => (
                  <CourseTable
                    key={courseIndex}
                    course={course}
                    selectedRole={selectedRole}
                  />
                ))
            : matchedData.courses.map((course, courseIndex) => (
                <CourseTable
                  key={courseIndex}
                  course={course}
                  selectedRole={selectedRole}
                  onSelect={() => handleCourseSelect(course.courseName)}
                />
              ))}
        </div>
      )}
    </div>
  );
};

export default PracticeList;
