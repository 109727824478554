import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../features/auth/authSlice";
import {
  registerDisplayName,
  getStats,
} from "../../features/competition/competitionSlice";
import { useState, useEffect } from "react";
import { FaMedal } from "react-icons/fa";
import { MdOutlineArrowDropDownCircle } from "react-icons/md";
import { FaTools, FaCode, FaPeopleCarry } from "react-icons/fa";
import { GiMailedFist } from "react-icons/gi";
import Spinner from "../../shared/Spinner";

function SalesforceLogout({ user }) {
  const { registerDisplayNameMessage, userStats, showSpinner } = useSelector(
    (state) => state.competitions
  );

  const navigate = useNavigate();

  const reRoute = (path) => {
    navigate(path);
  };

  const dispatch = useDispatch();
  const [submitError, setSubmitError] = useState("");

  useEffect(() => {
    dispatch(getStats());
  }, [dispatch, submitError]);

  function handleLogout() {
    dispatch(logout());
  }

  const [displayName, setDisplayName] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();

    if (displayName) {
      dispatch(registerDisplayName(displayName));
    } else {
      setSubmitError("Please enter a value.");
    }
  };

  const handleTyping = (event) => {
    setDisplayName(event.target.value);
  };

  let placeholderDisplayName =
    userStats?.userInfo?.displayName || "SyntaxSlingerCodey";

  let registerText = userStats?.userInfo?.displayName
    ? "Edit your Display Name, if you like:"
    : "Choose your Display Name:";
  return (
    <div className="bg-zinc-900 text-white flex flex-col justify-center items-center px-4 pb-9 text-sm">
      {showSpinner && <Spinner />}
      <div className="grid grid-cols-2 gap-4 w-full max-w-4xl overflow-y-auto ">
        <div className="bg-zinc-900 col-span-2 flex items-center justify-center text-4xl ">
          <FaMedal color="gold" className="mt-12 md:pt-0" />
        </div>
        <div className="space-y-4 col-span-2">
          <div className="bg-zinc-800 p-8 rounded-lg text-center mb-4 lg:mb-0">
            Connected to:{" "}
            <a
              className="hover:underline text-base hover:text-primary"
              href={user.toString()}
              target="_blank"
            >
              {user.toString()}
            </a>
          </div>
        </div>
        <div className="bg-zinc-800 p-4 md:p-8 rounded-lg">
          <h1 className="text-xl font-bold mb-4">
            {userStats?.userInfo?.displayName}
          </h1>
          <div className="flex flex-row justify-between">
            {" "}
            <div className="pb-2">Lessons Completed:</div>
            <div className="pb-2 align-end">
              {userStats?.completedProgressCount}
            </div>
          </div>
          <div className="flex flex-row justify-between">
            {" "}
            <div className="pb-2">Competition Participation:</div>
            <div className="pb-2 align-end">{userStats?.contestantCount}</div>
          </div>
          <div className="flex flex-row justify-between">
            {" "}
            <div className="pb-2">Competition Global Ranking:</div>
            <div className="pb-2 align-end">{userStats?.userRank}</div>
          </div>
          <div className="flex flex-row justify-between">
            {" "}
            <div className="pb-2">Competition Points:</div>
            <div className="pb-2 align-end">{userStats?.points}</div>
          </div>

          <p className="my-2"></p>
        </div>

        {/* Right column */}
        <div className="space-y-4">
          <div className="bg-zinc-800 py-8 px-8 rounded-lg text-md ">
            <p>
              There we go! <br /> You're one step closer to your goals.
            </p>
          </div>
          <div className="bg-zinc-800 p-5 md:p-8 rounded-lg">
            <p>
              Join the{" "}
              <a
                className="text-md align-left text-primary hover:underline"
                href="https://join.slack.com/t/campapexorg/shared_invite/zt-1yc61mwed-5RnC2pu7iITZzGZway1Srw"
                target="_blank"
              >
                Slack
              </a>{" "}
              community for support & live events.
            </p>{" "}
            <br />
            <p>
              <a
                className="text-md align-left text-primary hover:underline"
                href="https://www.buymeacoffee.com/CampApexDotOrg"
                target="_blank"
              >
                Buy me a coffee
              </a>
              ; fuel my late-night working sessions & cover server costs.
            </p>
            <br />
            <p>
              Help me spread the word! Share your success with other
              Trailblazers!{" "}
            </p>
          </div>
        </div>
        <div className="space-y-4 col-span-2">
          <div className="bg-zinc-800 p-8 rounded-lg text-center">
            <div>Rumor has it you're a pretty good dev. Wanna prove it?</div>

            <Link to="/compete" className="hover:underline text-base-300">
              Compete for the Global Top 25.
            </Link>
            <div className="collapse">
              <input type="checkbox" />
              <div className="collapse-title flex items-center justify-center">
                Register
                <MdOutlineArrowDropDownCircle color="gold" className="ml-2" />
              </div>
              <div className="collapse-content">
                <p className="pb-2">{registerText}</p>
                <div className="flex flex-row flex items-center justify-center">
                  <input
                    type="text"
                    placeholder={placeholderDisplayName}
                    value={displayName}
                    onChange={handleTyping}
                    className="input input-bordered w-full max-w-xs bg-zinc-900"
                  />
                  <Link
                    to=""
                    onClick={handleSubmit}
                    className="btn btn-primary text-center ml-2"
                  >
                    Register
                  </Link>
                </div>
                {registerDisplayNameMessage && (
                  <div className="mt-5 text-red-400">
                    {submitError}
                    {registerDisplayNameMessage}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <CategoryCard
            icon={<FaCode className="text-4xl xl:text-6xl mb-7 " size={32} />}
            title="Interactive Lessons"
            description="Apex, SOQL, & OOP. <br/><br/> Bite-sized lessons covering the fundamentals. Each paired with a challenge to solve."
            onClick={() => reRoute("/courses")}
          />
        </div>
        <div className="">
          <CategoryCard
            icon={<FaTools className="text-4xl text-6xl mb-7" size={32} />}
            title="Projects"
            description="Build a custom Salesforce application from scratch. Camp Apex is your product manager and QA team. <br/><br/> Implement the project's user stories. Will you choose clicks or code?"
            onClick={() => reRoute("/projects")}
          />
        </div>
        <div className="">
          <CategoryCard
            icon={<GiMailedFist className="text-4xl mb-7" size={32} />}
            title="Competitions"
            description="Think you're a pretty good developer? Prove it. <br/><br/>Time is ticking, compete against the world for a place on the Global Top 25. Competitions run monthly.<br/><br/>"
            onClick={() => reRoute("/compete")}
          />
        </div>
        <div className="">
          <CategoryCard
            icon={
              <FaPeopleCarry className="text-4xl text-6xl mb-7" size={32} />
            }
            title="Community Events"
            description="Virtual and In-Person Build Nights. <br/><br/> Meet up with other aspiring developers and build apps together."
            onClick={() => reRoute("/events")}
          />
        </div>
      </div>
    </div>
  );

  function CategoryCard({ icon, title, description, onClick }) {
    return (
      <div
        className="bg-zinc-800 p-6 shadow-md text-zinc-100 home-category h-full"
        onClick={onClick}
      >
        <div className="flex flex-cols ">
          {icon}
          <h3 className="text-lg xl:text-xl font-semibold mb-7 pl-2 text-white">
            {title}
          </h3>
        </div>
        <p
          className="text-md xl:text-md"
          dangerouslySetInnerHTML={{ __html: description }}
        ></p>
      </div>
    );
  }
}

export default SalesforceLogout;
