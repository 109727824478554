import React from "react";
import spinner from "../../assets/spinner2.gif";

function Spinner() {
  return (
    <div className="absolute inset-0 flex justify-center items-center z-10 backdrop-blur-[2px] object-contain backdrop-brightness-20 ">
      <img src={spinner} width={100} height={100} alt="loading..." />
    </div>
  );
}

export default Spinner;
