import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useSelector, useDispatch } from "react-redux";
import { getCourses } from "../../features/courses/courseSlice";
import CourseItem from "../Courses/CourseItem";
import CollectionTab from "./CollectionTab";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import Spinner from "../../shared/Spinner";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

function Courses() {
  const { courses, collections, openCollection, courseStatus, showSpinner } =
    useSelector((state) => state.courses);

  const dispatch = useDispatch();
  let params = useParams();

  const pageName = window.location.pathname.substring(
    1,
    window.location.pathname.length - 1
  );

  useEffect(() => {
    dispatch(getCourses(window.location.pathname.substring(1)));
  }, [dispatch, params]);

  if (!courses) {
    return <div className="h-screen">{showSpinner && <Spinner />}</div>;
  }

  let coursesToShow = [];

  courses.forEach((course) => {
    let setStatus = false;
    if (course.collection === openCollection?._id) {
      for (var i = 0; i < courseStatus.length; i++) {
        if (String(course._id).includes(courseStatus[i][0])) {
          let extensibleCourse = Object.create(course);
          extensibleCourse["status"] = courseStatus[i][1];
          coursesToShow.push(extensibleCourse);
          setStatus = true;
        }
      }
      if (!setStatus) {
        let extensibleCourse = Object.create(course);
        extensibleCourse["status"] = "Not Started";
        coursesToShow.push(extensibleCourse);
      }
    }
  });

  let coursesToShowStyle =
    coursesToShow.length === 1
      ? "lg:px-96 px-24"
      : "course-container mx-0 p-6 grid md:grid-cols-3 lg:grid-cols-2 gap-4 ";

  return (
    <div className="min-h-screen bg-zinc-900">
      <Helmet>
        <title>Courses | Camp Apex</title>
        <meta
          name="description"
          content="Explore a wide range of Salesforce Apex courses and challenges at Camp Apex. Learn by doing with hands-on practice and real coding challenges. Apex Fundamentals, SOQL, Apex Object Oriented Programming, and more."
        />
        <meta property="og:title" content="Courses | Camp Apex" />
        <meta
          property="og:description"
          content="Discover interactive Salesforce Apex courses designed to help you learn by doing. Get hands-on experience with coding challenges that build your skills."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`https://campapex.org${window.location.pathname}`}
        />
        <meta
          property="og:image"
          content="https://campapex.org/assets/hero6.png"
        />
        <link
          rel="canonical"
          href={`https://campapex.org${window.location.pathname}`}
        />
      </Helmet>

      <div className="flex flex-col justify-center items-center">
        <div>
          <h1 className="text-center font-semibold pt-9 lg:pt-16 pb-9">
            <CollectionTab collections={collections}></CollectionTab>
          </h1>
        </div>
        <div className="lg:pt-7 lg:px-20 px-9 ">
          <ReactMarkdown
            className="justify-center lg:px-32 text-center lg:pb-12 pb-9"
            children={openCollection?.description}
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
          />
        </div>

        <div className={coursesToShowStyle}>
          {coursesToShow.map((course, index) => {
            return (
              <div key={index} className="card p-3 lg:p-7 ">
                <CourseItem key={course._id} course={course} />
              </div>
            );
          })}
        </div>
      </div>

      <div className="text-center lg:pt-50 pt-24">
        <p>
          Have {pageName} suggestions?
          <a
            className="text-sky-300"
            href="https://tally.so/r/mDzZVj"
            target="_blank"
          >
            {" "}
            Let me know!
          </a>
        </p>
      </div>
      <div className="text-center ">
        <p>
          Want to write your own {pageName}?
          <a
            className="text-sky-300"
            href="https://tally.so/r/mDzZVj"
            target="_blank"
          >
            {" "}
            Let's figure it out.
          </a>
        </p>
      </div>
    </div>
  );
}

export default Courses;
