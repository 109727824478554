import { useState, useEffect } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { useSelector } from "react-redux";
import Spinner from "../../shared/Spinner";
import { testPassedMessages } from "../../../assets/testPassedMessages";

function MultiTestResults() {
  const { user } = useSelector((state) => state.auth);
  const { showSpinner, lesson } = useSelector((state) => state.courses);

  const [successMessage, setSuccessMessage] = useState(null);

  const { executionStatus } = useSelector((state) => state.courses);
  console.log(executionStatus);
  let executionResults = [];
  let allTestPassed = false;
  if (executionStatus?.data?.exceptionMessage) {
    try {
      executionResults = JSON.parse(
        executionStatus?.data?.exceptionMessage.replace(
          "TestResultException: ",
          ""
        )
      );
    } catch (e) {
      executionResults = executionStatus?.data?.exceptionMessage;
    }

    allTestPassed = executionResults.every((res) => {
      return res.pass;
    });

    if (allTestPassed) {
      if (!successMessage) {
        setSuccessMessage(
          testPassedMessages[
            Math.floor(Math.random() * testPassedMessages.length)
          ]
        );
      }
    } else if (successMessage && !allTestPassed) {
      setSuccessMessage("");
    }
  }

  useEffect(() => {
    setSuccessMessage(null);
  }, [lesson]);

  const [activeTestResult, setActiveTestResult] = useState("");

  const handleClick = (testResultId) => {
    setActiveTestResult(testResultId);
  };

  if (executionStatus?.data?.compileProblem) {
    return (
      <>
        <h3 className="text-md font-bold pl-4">Compilation Error:</h3>
        <p className="text-sm pl-4">{executionStatus.data.compileProblem}</p>
      </>
    );
  } else {
    return (
      <>
        {showSpinner && <Spinner />}
        <div className="bg-stone-900 overflow-y-auto h-full">
          <h1 className="font-bold text-lg pl-4 py-4 text-center">
            Test Results
          </h1>
          {executionResults.length ? (
            <div className="join join-vertical w-full">
              {executionResults?.map((result) => (
                <div
                  key={result.order}
                  onClick={() => handleClick(result.order)}
                >
                  <div
                    className={`collapse collapse-arrow join-item ${
                      activeTestResult === result.order ? "collapse-open" : ""
                    }`}
                  >
                    <div
                      className="collapse-title text-md font-xs"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <p className="pr-2">Scenario {result.order}</p>{" "}
                      {result.pass ? (
                        <FaCheckCircle color="green" />
                      ) : (
                        <MdCancel color="red" />
                      )}
                    </div>

                    <div className="collapse-content text-sm">
                      {<p className="pb-5">Scenario: {result.scenario}</p>}
                      {result.message && <p>Message: {result.message}</p>}
                    </div>
                  </div>
                </div>
              ))}
              <div className="text-center pt-9">{successMessage}</div>
            </div>
          ) : (
            <>
              <p className="pl-4 pt-5">Solve the challenge and click 'Run'.</p>

              <div className="text-center pt-9">{successMessage}</div>
            </>
          )}
        </div>
      </>
    );
  }
}

export default MultiTestResults;
