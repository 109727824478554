import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { FaCoins } from "react-icons/fa";
import { FiCheckCircle, FiTarget } from "react-icons/fi";
import { useSelector } from "react-redux";

function ProblemSet() {
  const navigate = useNavigate();

  const { contest } = useSelector((state) => state.competitions);
  const go = useCallback(
    (challengeId) =>
      navigate(`/compete/${contest?.contest?.tag}/${challengeId}`),
    [navigate]
  );

  const renderStatusIcon = (status) => {
    switch (status) {
      case "Accepted":
        return <FiCheckCircle color="green" />;
      case "Attempted":
        return <FiTarget color="orange" />;
      default:
        return null; // No icon for blank or undefined status
    }
  };

  return (
    <div className=" max-h-80">
      <table className="table  w-full">
        <thead className="">
          <tr>
            <th className="sticky top-0  bg-zinc-800"></th>
            <th className="sticky top-0  bg-zinc-800">Name</th>
            <th className="sticky top-0  bg-zinc-800">Status</th>
            <th className="sticky top-0  bg-zinc-800">Points</th>
          </tr>
        </thead>
        {contest?.challenges?.map((challenge, index) => (
          <tbody className="" key={index}>
            <tr
              onClick={() => {
                go(challenge._id);
              }}
              className="hover:text-purple-300"
            >
              <th className="bg-zinc-900 ">{challenge?.order}</th>
              <td className="bg-zinc-900 ">{challenge?.name}</td>
              <td className="bg-zinc-900">
                {renderStatusIcon(challenge?.challengeStatus)}
              </td>
              <td className="bg-zinc-900 flex align-center items-center ">
                {challenge?.points}
                <FaCoins color="gold" className="ml-2" />
              </td>
            </tr>
          </tbody>
        ))}
      </table>
    </div>
  );
}

export default ProblemSet;
