import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./components/pages/Home";
import AboutPage from "./components/pages/About";
import ContactPage from "./components/pages/Contact";
import AuthPage from "./components/pages/Auth/Auth";
import PrivacyPage from "./components/pages/Privacy/PrivacyPolicy";
import CookiePage from "./components/pages/Privacy/CookiePolicy";
import NotFound from "./components/pages/NotFound";
import Navbar from "./components/shared/Navbar";
import Footer from "./components/shared/Footer";
import CourseList from "./components/pages/Courses/CourseList";
import LessonList from "./components/pages/Courses/LessonList";
import PracticeList from "./components/pages/Practice/PracticeList";
import Lesson from "./components/pages/Lesson/Lesson";
import DataInstallation from "./components/pages/DataInstallation";
import CompeteHome from "./components/pages/Competition/CompeteHome";
import CompetitionInfo from "./components/pages/Competition/CompetitionInfo";
import CompetitionChallenge from "./components/pages/Competition/CompetitionChallenge";
import EventPage from "./components/pages/Event";
import CollabPage from "./components/pages/Collab";

import { getUser } from "./components/features/auth/authSlice";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  const { user } = useSelector((state) => state.auth);

  return (
    <>
      {/* <Header text="Camp Apex" /> */}
      <div className="flex flex-col min-h-screen bg-zinc-900">
        <Router>
          <Navbar user={user[0]?.instanceUrl} /> {/*Not happy with this*/}
          <div className="flex-grow">
            <Routes>
              <Route exact path="/" element={<HomePage />}></Route>
              <Route exact path="/auth" element={<AuthPage />}></Route>
              <Route exact path="/about" element={<AboutPage />}></Route>
              <Route exact path="/contact" element={<ContactPage />}></Route>
              <Route exact path="/compete" element={<CompeteHome />}></Route>
              <Route
                exact
                path="/compete/:tag"
                element={<CompetitionInfo />}
              ></Route>
              <Route
                exact
                path="/compete/:tag/:challengeId"
                element={<CompetitionChallenge />}
              ></Route>
              <Route exact path="/privacy" element={<PrivacyPage />}></Route>
              <Route exact path="/cookie" element={<CookiePage />}></Route>
              <Route exact path="/notfound" element={<NotFound />}></Route>
              <Route exact path="/events" element={<EventPage />}></Route>
              <Route exact path="/collab" element={<CollabPage />}></Route>
              <Route path="/*" element={<NotFound />}></Route>
              <Route
                path="/courses"
                element={
                  // <PrivateRoute>
                  <CourseList />
                  // </PrivateRoute>
                }
              />
              <Route
                path="/projects"
                element={
                  // <PrivateRoute>
                  <CourseList />
                  // </PrivateRoute>
                }
              />
              <Route path="/course/:slug" element={<LessonList />} />
              <Route path="/lesson/:lessonIdOrSlug" element={<Lesson />} />
              <Route path="/practice" element={<PracticeList />} />
              <Route path="/practice/:role/" element={<PracticeList />} />
              <Route
                path="/practice/:role/:course"
                element={<PracticeList />}
              />
              <Route
                path="/practice/:role/:course/:lessonIdOrSlug"
                element={<Lesson />}
              />

              <Route
                path="/data-installation/:dataKey"
                element={<DataInstallation />}
              />
            </Routes>
          </div>
          <Footer className="mt-auto" />
        </Router>
      </div>
    </>
  );
}

export default App;
