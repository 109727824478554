import React from "react";
import { useSelector } from "react-redux";
import CompetitionCard from "./CompetitionCard";
import Spinner from "../../shared/Spinner";

function CompetitionCardHolder({ competitions }) {
  const { showSpinner } = useSelector((state) => state.competitions);
  const [activeTab, setActiveTab] = React.useState("upcoming");

  const now = new Date();

  const pastCompetitions = competitions?.filter(
    (comp) => new Date(comp.enddate) < now
  );
  const upcomingCompetitions = competitions?.filter(
    (comp) => new Date(comp.enddate) >= now
  );

  return (
    <>
      {showSpinner && <Spinner />}
      <div className="flex grid grid-cols-3">
        <div className="tabs col-span-3 flex items-center justify-center">
          <a
            className={`tab ${activeTab === "upcoming" ? "tab-active" : ""}`}
            onClick={() => setActiveTab("upcoming")}
          >
            Upcoming Competitions
          </a>
          <a
            className={`tab ${activeTab === "past" ? "tab-active" : ""}`}
            onClick={() => setActiveTab("past")}
          >
            Past Competitions
          </a>
        </div>
        <div className="tabs col-span-3 flex items-center justify-center">
          {activeTab === "upcoming" &&
            upcomingCompetitions?.map((comp) => (
              <div key={comp._id}>
                <CompetitionCard competition={comp} />
              </div>
            ))}
          {activeTab === "past" &&
            pastCompetitions?.map((comp) => (
              <div key={comp._id}>
                <CompetitionCard competition={comp} />
              </div>
            ))}
        </div>
      </div>
    </>
  );
}

export default CompetitionCardHolder;
