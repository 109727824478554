import { Link } from "react-router-dom";
import { HiOutlineLightBulb } from "react-icons/hi";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import DiffEditorScreen from "./DiffEditorScreen";

import { executeLesson, setSolution } from "../../features/courses/courseSlice";

import { useDispatch, useSelector } from "react-redux";

function SolutionButton() {
  const { lesson, showSpinner } = useSelector((state) => state.courses);

  const dispatch = useDispatch();

  function handleUseSolution() {
    let body = {
      lessonId: lesson[0]._id,
      apexBody: lesson[0].solution.trim(),
      solutionUsed: true,
      lessonType: lesson[0].type,
    };
    dispatch(setSolution(lesson[0].solution.trim()));
    dispatch(executeLesson(body));
    timeout();
  }

  function timeout() {
    let timeoutCount = lesson[0].type === "multitest" ? 1500 : 400;
    if (showSpinner) {
      setTimeout(function () {
        timeout();
      }, timeoutCount);
    } else {
      window.location.reload();
    }
  }

  let UseSolutionButton = (
    <label
      to=""
      htmlFor="code-diff-modal"
      onClick={handleUseSolution}
      className="btn btn-sm btn-xs btn-secondary lg:lessonBtn lesson-btn-small"
    >
      Submit Solution
    </label>
  );

  return (
    <>
      <div className="tooltip" data-tip="View Solution">
        <label
          htmlFor="code-diff-modal"
          className="btn btn-xs bg-black lg:lessonBtn lesson-btn-small !w-16"
        >
          <HiOutlineLightBulb className="text-2xl" color="yellow" />
        </label>
      </div>
      <input type="checkbox" id="code-diff-modal" className="modal-toggle" />
      <div className="modal sm:modal-middle backdrop-blur">
        <div className="modal-box !max-w-7xl bg-[#1e1e1e]">
          <div className="grid grid-cols-2 gap-4 text-center pb-4 text-white">
            <div>Your Submission</div>
            <div>Solution</div>
          </div>
          <div className="">
            <DiffEditorScreen />
          </div>
          <div className="modal-action">
            <div className="grid grid-cols-3 gap-1 pb-4 pr-8 text-white">
              <div
                className="tooltip pt-3 justify-self-end pr-6"
                data-tip="This is a Diff between the code you last submitted and the solution we had in mind. You can either submit the provided solution as is or keep your version and make the changes yourself."
              >
                <AiOutlineQuestionCircle />
              </div>
              <div>
                <label
                  htmlFor="code-diff-modal"
                  className="btn bg-orange-800 btn-outline hover:bg-orange-900 hover:text-white btn-sm lg:lessonBtn lesson-btn-small"
                >
                  Keep my version
                </label>
              </div>
              <div className="flex-0 pl-2">{UseSolutionButton}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SolutionButton;
