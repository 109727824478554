import React, { useState, useEffect } from "react";

const CountdownTimer = ({ targetUtcDate, showDays }) => {
  const [timeLeft, setTimeLeft] = useState("");

  const calculateTimeLeft = () => {
    // Convert target date to UTC and get current date in UTC
    const targetDate = new Date(targetUtcDate).getTime();
    const currentDate = new Date().getTime();

    // Calculate the difference in milliseconds
    const difference = targetDate - currentDate;

    if (difference > 0) {
      // Convert difference to readable format (days, hours, minutes, seconds)
      let timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };

      if (showDays) {
        return `${timeLeft.days}d ${timeLeft.hours}h ${timeLeft.minutes}m ${timeLeft.seconds}s`;
      }

      return `${timeLeft.hours}h ${timeLeft.minutes}m ${timeLeft.seconds}s`;
    }

    return "";
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 0);

    return () => clearInterval(timer);
  }, []);

  return <div>{timeLeft}</div>;
};

export default CountdownTimer;
