import React from "react";
import CountdownTimer from "./CountdownTimer";
import { useNavigate } from "react-router-dom";

function CompetitionCard({ competition }) {
  function convertUTCToLocal(utcDateString) {
    const date = new Date(utcDateString);
    return date.toLocaleTimeString([], {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  const localDate = convertUTCToLocal(competition.startdate);
  let navigate = useNavigate();

  function handleClick() {
    navigate("/compete/" + competition?.tag);
  }

  // Ensure the top-level div is flex and items stretch to fill available height
  return (
    <div
      className="card p-4 !bg-zinc-900 w-96 h-full flex flex-col "
      onClick={handleClick}
    >
      <div className="border-solid border-2 border-white rounded-3xl shadow-xl flex flex-1 flex-col hover:border-purple-300">
        <div className="card-body rounded-3xl text-white bg-zinc-700 flex flex-col justify-between">
          {competition?.enddate > new Date().toISOString() &&
            competition?.startdate > new Date().toISOString() && (
              <CountdownTimer
                targetUtcDate={competition?.startdate}
                showDays={"true"}
              />
            )}
          {competition?.enddate > new Date().toISOString() &&
            competition?.startdate < new Date().toISOString() && (
              <div className="text-base-300">Happening Now!</div>
            )}
          <h2 className="text-white">{competition.name}</h2>
          <p className="text-white">{competition.type}</p>
          <p className="text-white">{competition.location}</p>
          <p className="text-white">{localDate}</p>
        </div>
      </div>
    </div>
  );
}

export default CompetitionCard;
