import axios from "axios";
const API_URL_COURSE = "/api/courses/";
const API_URL__GET_LESSONS = "/api/getChallenges/";
const API_URL_LESSON = "/api/lesson/";

const getCourses = async (courseType) => {
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   };

  //const response = await axios.get(API_URL, config);

  let response;
  try {
    response = await axios.get(API_URL_COURSE + courseType);
  } catch (err) {
    console.log(err);
  }
  return response.data;
};

const getLessonsForCourse = async (slug) => {
  const response = await axios.get(API_URL__GET_LESSONS + slug);
  return response.data;
};

const getLesson = async (challengeIdOrSlug) => {
  let response;
  try {
    response = await axios.get(API_URL_LESSON + challengeIdOrSlug);
  } catch (e) {
    console.log(e);
  }

  return response.data;
};

const getInstallData = async (dataKey) => {
  let response;
  try {
    response = await axios.get("/api/installData/" + dataKey);
  } catch (e) {
    console.log(e);
  }

  return response.data;
};

const getPackageStatus = async (packageId) => {
  let response;
  try {
    response = await axios.get("/api/packageStatus/" + packageId);
  } catch (e) {
    console.log(e);
  }

  return response.data;
};

const executeLesson = async (payload) => {
  const options = {
    method: "POST",
    data: payload,
    url: "/api/submit/",
  };

  let response = await axios(options);

  return response.data;
};

const executeReadingCompleted = async (payload) => {
  const options = {
    method: "POST",
    data: payload,
    url: "/api/submitReading/",
  };

  let response = await axios(options);

  return response.data;
};

const executeCodeReset = async (payload) => {
  const options = {
    method: "POST",
    data: payload,
    url: "/api/reset/",
  };

  let response = await axios(options);

  return response.data;
};

const getAuthor = async (authorId) => {
  let response;
  try {
    response = await axios.get("/api/getAuthor/" + authorId);
  } catch (e) {
    console.log(e);
  }

  return response.data;
};

const getEvents = async () => {
  const options = {
    method: "GET",
    url: "/api/getEvents/",
  };

  let response = await axios(options);

  return response.data;
};

const fetchPracticeData = async () => {
  const options = {
    method: "GET",
    url: "/api/getPracticeData/",
  };

  let response = await axios(options);
  return response.data;
};

const courseService = {
  getLessonsForCourse,
  getCourses,
  getLesson,
  executeLesson,
  executeReadingCompleted,
  executeCodeReset,
  getPackageStatus,
  getInstallData,
  getEvents,
  getAuthor,
  fetchPracticeData,
};

export default courseService;
