import { useState, useEffect } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { getPackageStatus } from "../../features/courses/courseSlice";
import Spinner from "../../shared/Spinner";
import config from "../../../app.config.json";
import { testPassedMessages } from "../../../assets/testPassedMessages";

function TestResults({ lessonName }) {
  const { user } = useSelector((state) => state.auth);
  const { packageInstalled, showSpinner, lesson } = useSelector(
    (state) => state.courses
  );
  const [packageURL, setPackageURL] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPackageStatus(config[lessonName]));
  }, [dispatch, lessonName]);
  useEffect(() => {
    if (!packageURL) {
      if (user[0]?.instanceUrl) {
        setPackageURL(
          user[0]?.instanceUrl +
            "/packaging/installPackage.apexp?p0=" +
            config[lessonName]
        );
      }
    }
  }, [user]);

  const { executionStatus } = useSelector((state) => state.courses);
  let executionResults = [];
  let allTestPassed = false;
  if (executionStatus?.data?.exceptionMessage) {
    try {
      executionResults = JSON.parse(
        executionStatus?.data?.exceptionMessage.replace(
          "TestResultException: ",
          ""
        )
      );
    } catch (e) {
      executionResults = executionStatus?.data?.exceptionMessage;
    }

    allTestPassed = executionResults.every((res) => {
      return res.pass;
    });

    if (allTestPassed) {
      if (!successMessage) {
        setSuccessMessage(
          testPassedMessages[
            Math.floor(Math.random() * testPassedMessages.length)
          ]
        );
      }
    } else if (successMessage && !allTestPassed) {
      setSuccessMessage("");
    }
  }

  if (executionStatus?.data?.compileProblem) {
    <>
      <h3 className="text-md font-bold pl-4">Compilation Error:</h3>
      <p className="pl-4">{executionResults.compileProblem}</p>
    </>;
  }
  useEffect(() => {
    setSuccessMessage(null);
  }, [lesson]);

  const [activeTestResult, setActiveTestResult] = useState("");

  const handleClick = (testResultId) => {
    setActiveTestResult(testResultId);
  };

  return (
    <>
      {showSpinner && <Spinner />}
      <div className="bg-stone-900 overflow-y-auto">
        <h1 className="font-bold text-lg pl-4 py-4 text-center border-b border-b-red-200">
          Test Results
        </h1>
        {executionResults.length ? (
          <div className="join join-vertical w-full">
            {executionResults?.map((result) => (
              <div key={result.order} onClick={() => handleClick(result.order)}>
                <div
                  className={`collapse collapse-arrow join-item ${
                    activeTestResult === result.order ? "collapse-open" : ""
                  }`}
                >
                  <div
                    className="collapse-title text-md font-xs"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <p className="pr-2">Scenario {result.order}</p>{" "}
                    {result.pass ? (
                      <FaCheckCircle color="green" />
                    ) : (
                      <MdCancel color="red" />
                    )}
                  </div>

                  <div className="collapse-content text-sm">
                    {<p className="pb-5">Scenario: {result.scenario}</p>}
                    {result.message && <p>Message: {result.message}</p>}
                  </div>
                </div>
              </div>
            ))}
            <div className="text-center pt-9">{successMessage}</div>
          </div>
        ) : (
          <>
            <p className="pl-4 pt-5">
              Implement the solution in your org, then return here and click
              'Run Tests'.
            </p>

            {!packageInstalled && user[0] && (
              <p className="pl-4 pt-5">
                <>
                  Heads Up: You'll need to install the latest version of the{" "}
                  <a href={packageURL} target="_blank">
                    CampApex manged package
                  </a>{" "}
                  for this challenge.
                </>
              </p>
            )}
            <div className="text-center pt-9">{successMessage}</div>
          </>
        )}
      </div>
    </>
  );
}

export default TestResults;
