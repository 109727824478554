function InstallPackage({ user, lesson }) {
  const hasValidData =
    user.length !== 0 && lesson.length !== 0 && lesson[0]?.installLink;

  const installationLink = hasValidData
    ? `${user[0]?.instanceUrl}${lesson[0].installLink}`
    : null;

  return (
    <>
      {installationLink && (
        <>
          <div className="flex justify-center pt-3">Install The Package:</div>
          <a
            className="text-red-300 flex justify-center pb-4"
            href={installationLink}
          >
            {installationLink}
          </a>
        </>
      )}
    </>
  );
}

export default InstallPackage;
