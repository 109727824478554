import { useState, useEffect } from "react";
import CollectionItem from "./CollectionItem";
import { useSelector, useDispatch } from "react-redux";
import { setOpenCollection } from "../../features/courses/courseSlice";

function CollectionTab({ collections }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setOpenCollection(collections?.[0]));
  }, [dispatch]);

  return (
    <div className=" flex justify-center">
      <div className="tabs grid md:grid-rows-1 md:grid-flow-col">
        {collections.map((collection, index) => {
          return (
            <div key={index} className="">
              <CollectionItem key={collection._id} collection={collection} />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CollectionTab;
