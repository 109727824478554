import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../components/features/auth/authSlice";
import courseReducer from "../components/features/courses/courseSlice";
import competitionReducer from "../components/features/competition/competitionSlice";
//import lessonReducer from "../components/features/courses/lessonSlice";
//import noteReducer from "../features/notes/noteSlice";

export const store = configureStore({
  reducer: {
    courses: courseReducer,
    auth: authReducer,
    competitions: competitionReducer,
    //lesson: lessonReducer,
  },
});
