import React from "react";
import LessonMarkdown from "./LessonMarkdown";
import CodeEditor from "./CodeEditor";
import ResultPanel from "./ResultPanel";
import LessonFooter from "./LessonFooter";

const Tabs = ({ lesson }) => {
  const [openTab, setOpenTab] = React.useState(1);
  return (
    <>
      <div className="flex flex-wrap">
        <div className="bg-black text-white text-center w-screen text-xs py-4 px-10">
          Big site, small screen? Not a great combo. For the best experience,
          switch to desktop view! 😎
        </div>
        <div className="w-full">
          <ul
            className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
            role="tablist"
          >
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                  (openTab === 1
                    ? "text-white bg-base-200"
                    : "text-white bg-base")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(1);
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                Lesson
              </a>
            </li>
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal" +
                  (openTab === 2
                    ? "text-white bg-base-200"
                    : "text-white bg-base")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(2);
                }}
                data-toggle="tab"
                href="#link2"
                role="tablist"
              >
                Code Editor
              </a>
            </li>
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                  (openTab === 3
                    ? "text-white bg-base-200"
                    : "text-white bg-base")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(3);
                }}
                data-toggle="tab"
                href="#link3"
                role="tablist"
              >
                Result Panel
              </a>
            </li>
          </ul>
          <div className="flex flex-col h-4/6 block">
            <div className="px-4 py-5 flex-auto">
              <div className="tab-content tab-space">
                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                  <LessonMarkdown lesson={lesson} />
                </div>
                <div
                  className={openTab === 2 ? "block h-screen" : "hidden"}
                  id="link2"
                >
                  <CodeEditor key={lesson[0]?._id} lesson={lesson[0]} />
                </div>
                <div
                  className={openTab === 3 ? "block" : "hidden"}
                  id="link3 bg-zinc-900"
                >
                  <ResultPanel />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default function MobileLesson({ lesson }) {
  return (
    <>
      <Tabs lesson={lesson} />
      <LessonFooter lesson={lesson} />
    </>
  );
}
