import { useSelector, useDispatch } from "react-redux";
import {
  executeLesson,
  executeReadingCompleted,
  semanticValidationException,
} from "../../features/courses/courseSlice";
import { Link } from "react-router-dom";
import { getUser } from "../../features/auth/authSlice";

function SubmitButton({ user }) {
  const { solution, lesson } = useSelector((state) => state.courses);

  let moduleType = "Run";
  if (lesson[0].editorlessChallenge) {
    moduleType = "Run Tests";
  }

  const dispatch = useDispatch();

  let RunButton;
  if (!user?.toString() && lesson[0]?.challenge) {
    RunButton = (
      <Link
        className="btn btn-sm btn-xs btn-primary lg:lessonBtn lesson-btn-small"
        target="_blank"
        to={"/auth"}
      >
        Login to Run
      </Link>
    );
  } else if (
    !lesson[0]?.challenge &&
    !lesson[0].progress[0]?.status &&
    user?.toString()
  ) {
    RunButton = (
      <Link
        to=""
        onClick={handleReadingComplete}
        className="btn btn-sm btn-xs btn-primary lg:lessonBtn lesson-btn-small"
      >
        Mark as Read
      </Link>
    );
  } else if (lesson[0]?.challenge) {
    RunButton = (
      <Link
        to=""
        onClick={handleSubmit}
        className="btn btn-sm btn-xs btn-primary lg:lessonBtn lesson-btn-small"
        disabled={!solution && !lesson[0].editorlessChallenge}
      >
        {moduleType}
      </Link>
    );
  }

  function handleSubmit() {
    dispatch(getUser());
    let validationError;
    if (
      !lesson[0].js &&
      !lesson[0].editorlessChallenge &&
      lesson[0].type !== "SOQL"
    ) {
      validationError = basicSemanticValidation(solution);
    }
    if (!validationError) {
      let body = {
        lessonId: lesson[0]._id,
        apexBody: solution.trim(),
        lessonType: lesson[0].type,
      };
      dispatch(executeLesson(body)); //.catch(toast.error);
    } else {
      dispatch(semanticValidationException(validationError));
    }
  }

  function handleReadingComplete() {
    dispatch(getUser());
    let body = {
      lessonId: lesson[0]._id,
    };
    dispatch(executeReadingCompleted(body));
  }

  function basicSemanticValidation(apexBody) {
    apexBody = apexBody.trim();
    if (!apexBody.includes(";")) {
      return "Unable to compile. You might be missing a semicolon";
    }
    return null;
  }

  return <div>{RunButton}</div>;
}

export default SubmitButton;
