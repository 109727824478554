import { executeCodeReset } from "../../features/courses/courseSlice";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { getUser } from "../../features/auth/authSlice";
import { VscDebugRestartFrame } from "react-icons/vsc";
import SolutionButton from "./SolutionButton";
import SubmitButton from "./SubmitButton";

function ActionButton() {
  const { lesson } = useSelector((state) => state.courses);
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  function handleReset() {
    dispatch(
      executeCodeReset({
        lessonId: lesson[0]._id,
      })
    );
    setTimeout(function () {
      window.location.reload();
    }, 500);
  }

  let ResetButton = (
    <div className="tooltip" data-tip="Reset Challenge and Clear Code">
      <Link
        to=""
        onClick={handleReset}
        className="btn btn-sm btn-xs hover:bg-gray-900 bg-black lg:lessonBtn lesson-btn-small !w-16"
      >
        <VscDebugRestartFrame className="text-2xl" />
      </Link>
    </div>
  );

  return (
    <>
      <div className="flex-0">
        <SubmitButton user={user} />
      </div>
      {user?.toString() &&
        !lesson[0].editorlessChallenge &&
        lesson[0]?.progress[0]?.attemptCounter > 0 &&
        lesson[0]?.challenge && (
          <div className="flex-0 pl-2">{ResetButton}</div>
        )}
      {user?.toString() &&
        !lesson[0].editorlessChallenge &&
        lesson[0]?.progress[0]?.attemptCounter > 1 && (
          <div className="flex-0">
            <SolutionButton />
          </div>
        )}
    </>
  );
}

export default ActionButton;
