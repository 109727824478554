import { FaCampground } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { SiSalesforce } from "react-icons/si";
import { useState, useEffect } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { logout } from "../features/auth/authSlice";

import PropTypes from "prop-types";

function SimpleNav({ title, user }) {
  const dispatch = useDispatch();
  function handleLogout() {
    dispatch(logout());
  }

  const [isDesktop, setDesktop] = useState(window.innerWidth > 1050);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 1050);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const hideNav = () => {
    const elem = document.activeElement;
    if (elem) {
      elem?.blur();
    }
  };

  return (
    <div className="navbar bg-black relative ">
      <div className="navbar-start lg:pl-8">
        <FaCampground className="inline pr-2 text-3xl" />
        <Link to="/" className="lg:text-lg font-bold align-middle">
          {title}
        </Link>
      </div>
      <button className="navbar-end fill-current lg:mr-10 ">
        <div className="dropdown dropdown-end ">
          <div tabIndex={0} role="button" className="mr-3 ">
            <GiHamburgerMenu className="text-xl hover:text-primary" />
          </div>
          <ul
            tabIndex={0}
            className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-black rounded-box w-52"
          >
            <li onClick={hideNav}>
              <Link className="text-base" to="/courses">
                Courses
              </Link>
            </li>
            <li onClick={hideNav}>
              <Link className="text-base" to="/compete">
                Competitions
              </Link>
            </li>
            <li onClick={hideNav}>
              <Link className="text-base" to="/projects">
                Projects
              </Link>
            </li>
          </ul>
        </div>
        {user ? (
          <div className="dropdown dropdown-end ">
            <div tabIndex={0} role="button" className="mr-3 ">
              <SiSalesforce color="#009EDB" className="inline pr-2 text-3xl" />
            </div>
            <ul
              tabIndex={0}
              className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-black rounded-box w-52"
            >
              <li onClick={hideNav}>
                <Link className="text-base" to="/auth">
                  Profile
                </Link>
              </li>
              <li onClick={handleLogout}>
                <Link className="text-base">Logout</Link>
              </li>
            </ul>
          </div>
        ) : (
          <>
            {isDesktop ? (
              <Link className="text-base" target="_blank" to="/auth">
                Login
              </Link>
            ) : (
              <Link className="text-base pr-4" target="_blank" to="/auth">
                <SiSalesforce></SiSalesforce>
              </Link>
            )}
          </>
        )}
      </button>
    </div>
  );
}

SimpleNav.defaultProps = {
  title: "Camp Apex",
};

SimpleNav.propTypes = {
  title: PropTypes.string,
};

export default SimpleNav;
