import React from "react";
import { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { testPassedMessages } from "../../../assets/testPassedMessages";

function ResultPanel() {
  const { executionStatus } = useSelector((state) => state.courses);
  const [successMessage, setSuccessMessage] = useState(null);

  useEffect(() => {
    if (executionStatus?.data?.success) {
      setSuccessMessage(
        testPassedMessages[
          Math.floor(Math.random() * testPassedMessages.length)
        ]
      );
    }
  }, [executionStatus?.data?.success]);

  let panelName = "Result Panel";
  let executionResults = executionStatus?.data;
  let body = (
    <>
      <h1 className="font-bold text-lg pl-4">{panelName}</h1>
      <h3 className="text-sm pl-4">Hit the Run button to test your code.</h3>
    </>
  );

  if (executionResults?.success) {
    body = (
      <div className="pl-4">
        <h1 className="font-bold text-lg">{panelName}</h1>
        {successMessage}
      </div>
    );
  } else if (executionResults?.success === false) {
    body = (
      <>
        {executionResults.compileProblem && (
          <>
            <h1 className="font-bold text-lg pl-4">{panelName}</h1>
            <br />
            <h3 className="text-md font-bold pl-4">Compilation Error:</h3>
            <p className="pl-4">{executionResults.compileProblem}</p>
          </>
        )}

        {executionResults.exceptionMessage && (
          <>
            <h1 className="font-bold text-lg pl-4">{panelName}</h1>
            <br />
            <h3 className="font-bold text-sm pl-4">Test Result:</h3>
            <p className="pl-4">
              {executionResults.exceptionMessage.replace("TestException: ", "")}
            </p>
          </>
        )}
      </>
    );
  } else if (executionStatus === "AUTH ERROR") {
    panelName = "Auth Error";
    body = (
      <>
        <h1 className="font-bold text-lg text-red-300 pb-1 pl-4">
          {panelName}
        </h1>
        <h3 className="text-sm text-red-300">
          You've been logged out due to inactivity. Save your work locally and
          login to continue.
        </h3>
      </>
    );
  }

  return (
    <>
      <div className="">{body}</div>
    </>
  );
}

export default ResultPanel;
