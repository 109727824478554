import Modal from "react-modal";
import { useEffect } from "react";
import { getLesson } from "../../features/courses/courseSlice";
import { useParams } from "react-router-dom";
import CodeEditor from "./CodeEditor";
import Split from "react-split";
import ResultPanel from "./ResultPanel";
import LessonFooter from "./LessonFooter";
import MobileLesson from "./MobileLesson";
import LessonMarkdown from "./LessonMarkdown";
import LessonNav from "./LessonNav";
import TestResults from "./TestResults";
import Spinner from "../../shared/Spinner";
import { useState } from "react";

function EditorlessChallenge({ lesson }) {
  const bottomDivStyle = {
    position: "fixed",
    bottom: 0,
    width: "100%",
    // Add any additional styling you need here
  };

  return (
    // <div className="">
    //
    //   <div class="flex flex-col h-full">
    //     <div className="flex-1  bg-[#070b14] px-64 pb-44">
    //       <LessonMarkdown lesson={lesson} />
    //     </div>

    //     <div className="flex-1 pb-16 overflow-x-auto ">
    //
    //     </div>
    //     <div style={bottomDivStyle} className="text-center ">
    //       <LessonFooter lesson={lesson} />
    //     </div>
    //   </div>
    // </div>
    <>
      <div className="bg-black editorless-container">
        <div className="content">
          <LessonNav lesson={lesson} />
          <Split className="splitHor max-h-72" minSize={600} gutterSize={8}>
            <LessonMarkdown lesson={lesson} />
            <TestResults lessonName={lesson[0]?.courseDetails?.name} />
          </Split>
        </div>
      </div>
      <LessonFooter lesson={lesson} />
    </>
  );
}

export default EditorlessChallenge;
