import Editor from "@monaco-editor/react";
import { useSelector, useDispatch } from "react-redux";
import { useRef } from "react";
import { setSolution } from "../../features/courses/courseSlice";

function CodeEditor({ lesson }) {
  const dispatch = useDispatch();
  const editorRef = useRef(null);
  const { solution } = useSelector((state) => state.courses);

  function handleEditorChange(value, event) {
    dispatch(setSolution(value));
  }

  function handleEditorDidMount(editor, monaco) {
    editorRef.current = editor;
  }

  function handleEditorBeforeMount(monaco) {
    let theme = {
      base: "vs-dark",
      inherit: true,
      rules: [{ token: "type", foreground: "569cd6" }],
      colors: {
        // "editor.background": "#192544",
      },
    };
    monaco.editor.defineTheme("my-theme", theme);
  }

  let defaultCode = lesson?.progress[0]?.lastSubmission
    ? lesson.progress[0].lastSubmission
    : lesson?.boilerPlateCode;

  if (lesson) {
    return (
      <Editor
        className="inline-block"
        onChange={handleEditorChange}
        onMount={handleEditorDidMount}
        beforeMount={handleEditorBeforeMount}
        defaultLanguage="apex"
        theme="my-theme"
        defaultValue={defaultCode}
        options={{
          fontSize: "14px",
          minimap: {
            enabled: false,
          },
          scrollbar: {
            alwaysConsumeMouseWheel: false,
          },
          scrollBeyondLastLine: false,
        }}
      />
    );
  } else {
    return <></>;
  }
}

export default CodeEditor;
