const testPassedMessages = [
  <h3 className="text-sm">
    Tests Passed! Cheers!{"  "}
    <span role="img" aria-label="cheers" className="text-2xl">
      🍻
    </span>
  </h3>,
  <h3 className="text-sm">
    Tests Passed! Let's get it!{"  "}
    <span role="img" aria-label="cheers" className="text-2xl">
      💰
    </span>
  </h3>,
  <h3 className="text-sm">
    Tests Passed! You're making it look easy!{"  "}
    <span role="img" aria-label="cheers" className="text-2xl">
      💪
    </span>
  </h3>,
  <h3 className="text-sm">
    Tests Passed! You did THAT!{"  "}
    <span role="img" aria-label="cheers" className="text-2xl">
      🙌
    </span>
  </h3>,
  <h3 className="text-sm">
    Tests Passed! You're unstoppable.{"  "}
    <span role="img" aria-label="cheers" className="text-2xl">
      🏃
    </span>
  </h3>,
  <h3 className="text-sm">
    Tests Passed! Look at you go!{"  "}
    <span role="img" aria-label="cheers" className="text-2xl">
      🔥
    </span>
  </h3>,
  <h3 className="text-sm">
    Tests Passed! You're making waves!{"  "}
    <span role="img" aria-label="cheers" className="text-2xl">
      🌊
    </span>
  </h3>,
  <h3 className="text-sm">
    Tests Passed! They're not ready for you!{"  "}
    <span role="img" aria-label="cheers" className="text-2xl">
      👑
    </span>
  </h3>,
  <h3 className="text-sm">
    Tests Passed! Dream big!{"  "}
    <span role="img" aria-label="cheers" className="text-2xl">
      🌠
    </span>
  </h3>,
  <h3 className="text-sm">
    Tests Passed! They said it couldn't be done, but you're out here doing it!
    {"  "}
    <span role="img" aria-label="cheers" className="text-2xl">
      🌟
    </span>
  </h3>,
  <h3 className="text-sm">
    Tests Passed! Your potential is showing!{"  "}
    <span role="img" aria-label="cheers" className="text-2xl">
      🌱
    </span>
  </h3>,
  <h3 className="text-sm">
    Tests Passed! Pop off!{"  "}
    <span role="img" aria-label="cheers" className="text-2xl">
      👏
    </span>
  </h3>,
  <h3 className="text-sm">
    Tests Passed! Talk is cheap, you're out here putting in the work!{"  "}
    <span role="img" aria-label="cheers" className="text-2xl">
      👏
    </span>
  </h3>,
];

export { testPassedMessages };
