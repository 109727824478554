import Editor, { DiffEditor, useMonaco, loader } from "@monaco-editor/react";
import { useSelector, useDispatch } from "react-redux";
import { useRef } from "react";
import { setSolution } from "../../features/courses/courseSlice";

function DiffEditorScreen() {
  const dispatch = useDispatch();
  const editorRef = useRef(null);
  const { lesson } = useSelector((state) => state.courses);

  function handleEditorChange(value, event) {
    dispatch(setSolution(value));
  }

  function handleEditorDidMount(editor, monaco) {
    editorRef.current = editor;
  }

  function handleEditorBeforeMount(monaco) {
    let theme = {
      base: "vs-dark",
      inherit: true,
      rules: [{ token: "type", foreground: "569cd6" }],
      colors: {
        // "editor.background": "#192544",
      },
    };
    monaco.editor.defineTheme("my-theme", theme);
  }

  const originalCode =
    lesson[0]?.progress[0]?.lastSubmission == null
      ? ""
      : lesson[0].progress[0].lastSubmission;

  const modifiedCode = lesson[0]?.solution == null ? "" : lesson[0].solution;

  if (lesson[0]) {
    return (
      <DiffEditor
        className="inline-block"
        height="50vh"
        onChange={handleEditorChange}
        onMount={handleEditorDidMount}
        beforeMount={handleEditorBeforeMount}
        defaultLanguage="apex"
        theme="my-theme"
        original={originalCode}
        modified={modifiedCode}
        options={{
          fontSize: "14px",
          minimap: {
            enabled: false,
          },
          scrollbar: {
            alwaysConsumeMouseWheel: false,
          },
          scrollBeyondLastLine: false,
          readOnly: true,
        }}
      />
    );
  } else {
    return <></>;
  }
}

export default DiffEditorScreen;
