import Editor from "@monaco-editor/react";
import { useRef } from "react";

function MiniEditor() {
  const editorRef = useRef(null);

  let code = `public class NameBuilder {
    
    /* This is the same editor you'll use in the lessons */
    private String firstName = '';
    private String lastName = '';
    private String fullName = '';

    public void setFirstName(String firstName) {
        this.firstName = firstName;
        updateFullName();
    }

    // Write comments as you go
    public void setLastName(String lastName) {
        this.lastName = lastName;
        updateFullName();
    }

    private void updateFullName() {
      this.fullName = this.this.firstName + ' ' + lastName;
    }

    // Don't be shy, type whatever you want
    public String getFullName() {
        return this.fullName;
    }
}

/********************************************************
* Camp Apex will compile and test your code to let you know how you did!
********************************************************/

`;

  function handleEditorDidMount(editor, monaco) {
    editorRef.current = editor;
  }

  function handleEditorBeforeMount(monaco) {
    let theme = {
      base: "vs-dark",
      inherit: true,
      rules: [{ token: "type", foreground: "569cd6" }],
      colors: {
        // "editor.background": "#192544",
      },
    };
    monaco.editor.defineTheme("my-theme", theme);
  }

  return (
    <Editor
      onMount={handleEditorDidMount}
      beforeMount={handleEditorBeforeMount}
      defaultLanguage="apex"
      theme="my-theme"
      defaultValue={code}
      options={{
        fontSize: "12px",
        minimap: {
          enabled: false,
        },
        scrollbar: {
          alwaysConsumeMouseWheel: false,
        },
        scrollBeyondLastLine: false,
      }}
    />
  );
}

export default MiniEditor;
