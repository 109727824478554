import Modal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getLesson } from "../../features/courses/courseSlice";
import { useParams } from "react-router-dom";
import CodeEditor from "./CodeEditor";
import Split from "react-split";
import ResultPanel from "./ResultPanel";
import LessonFooter from "./LessonFooter";
import MobileLesson from "./MobileLesson";
import LessonMarkdown from "./LessonMarkdown";
import EditorlessChallenge from "./EditorlessChallenge";
import LessonNav from "./LessonNav";
import Spinner from "../../shared/Spinner";
import MultiTestResults from "./MultiTestResults";
import { Helmet } from "react-helmet";

Modal.setAppElement("#root");

function Lesson() {
  const { lesson, showSpinner } = useSelector((state) => state.courses);
  const dispatch = useDispatch();
  const { lessonIdOrSlug } = useParams();

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const hasChallenge = lesson[0]?.challenge;
  const editorlessChallenge = lesson[0]?.editorlessChallenge;

  useEffect(() => {
    try {
      dispatch(getLesson(lessonIdOrSlug)).unwrap(); //.catch(toast.error);
    } catch (err) {
      console.log(err);
    }
  }, [lessonIdOrSlug, dispatch]);

  Array.from(document.links)
    .filter((link) => link.hostname !== window.location.hostname)
    .forEach((link) => (link.target = "_blank"));

  return (
    <>
      <Helmet>
        <title>{lesson[0]?.name || "Lesson"} | Camp Apex</title>
        <meta
          name="description"
          content={
            lesson[0]?.challenge ||
            "Learn Salesforce Apex with this lesson on Camp Apex. Practice coding and gain a better understanding of key concepts."
          }
        />
        <meta
          property="og:title"
          content={`${lesson[0]?.name || "Lesson"} | Camp Apex`}
        />
        <meta
          property="og:description"
          content={
            lesson[0]?.challenge ||
            "This lesson helps you learn Salesforce Apex through practical coding challenges and clear explanations."
          }
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`https://campapex.org${window.location.pathname}`}
        />
        <meta
          property="og:image"
          content="https://campapex.org/assets/hero6.png"
        />
        <link
          rel="canonical"
          href={`https://campapex.org${window.location.pathname}`}
        />
      </Helmet>

      {isMobile ? (
        <MobileLesson lesson={lesson} />
      ) : editorlessChallenge ? (
        <div className="max-h-72 ">
          <EditorlessChallenge lesson={lesson}></EditorlessChallenge>
        </div>
      ) : (
        <div>
          {showSpinner && <Spinner />}
          <LessonNav lesson={lesson} />
          {hasChallenge ? (
            <Split className="splitHor max-h-72" minSize={600} gutterSize={8}>
              <LessonMarkdown lesson={lesson} />
              <Split
                direction="vertical"
                minSize={[300, 200]}
                gutterSize={8}
                snapOffset={1}
                dragInterval={1}
              >
                <div className="block">
                  <CodeEditor key={lesson[0]?._id} lesson={lesson[0]} />
                </div>
                <div className="bg-zinc-900 text-white py-2 overflow-y-scroll">
                  {lesson[0]?.type === "multitest" ? (
                    <MultiTestResults type={"multitest"} />
                  ) : (
                    <ResultPanel />
                  )}
                </div>
              </Split>
            </Split>
          ) : (
            <div className="splitHor max-h-[72vh] overflow-y-scroll">
              <LessonMarkdown lesson={lesson} />
            </div>
          )}
          <LessonFooter lesson={lesson} />
        </div>
      )}
    </>
  );
}

export default Lesson;
