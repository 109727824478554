import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";

function CourseItem({
  course: {
    slug,
    author1,
    author1Link,
    name,
    order,
    description,
    difficulty,
    state,
    dropDate,
    status,
  },
}) {
  return (
    <>
      <p className="font-bold text-xs lg:text-base ">{order + ". " + name}</p>
      {author1 && (
        <p className="text-xs lg:text-md pt-2 pb-1">
          Author:{" "}
          {author1Link ? (
            <a href={author1Link} target="_blank" rel="noopener noreferrer	 ">
              <span className="hover:underline  text-purple-500">
                {author1}
              </span>
            </a>
          ) : (
            author1
          )}
        </p>
      )}
      <p></p>
      {state !== "Preview" && (
        <div className="text-bold pt-1">
          {status === "Not Started" && (
            <div className="indicator-item badge badge-error font-semibold text-xs">
              {status}
            </div>
          )}
          {status === "In Progress" && (
            <div className="indicator-item badge badge-warning font-semibold text-xs">
              {status}
            </div>
          )}
          {status === "Completed" && (
            <div className="indicator-item badge badge-success font-semibold text-xs">
              {status}
            </div>
          )}
        </div>
      )}

      {description && (
        <ReactMarkdown
          className="pt-2 text-xs lg:text-base"
          children={description}
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeRaw]}
        />
      )}
      {state !== "Preview" && (
        <div className="pt-4">
          <Link className="btn btn-primary" to={`/course/${slug}`}>
            Start!
          </Link>
        </div>
      )}
      {state === "Preview" && (
        <div className="pt-8 text-sm lg:text-base">Available: {dropDate}</div>
      )}
    </>
  );
}

CourseItem.propTypes = {
  course: PropTypes.object.isRequired,
};

export default CourseItem;
