import Modal from "react-modal";
import { useEffect } from "react";
import CodeEditor from "./CodeEditor";
import Split from "react-split";
import ResultPanel from "./ResultPanel";
import CompetitionFooter from "./CompetitionFooter";
import CompetitionMarkdown from "./CompetitionMarkdown";
import Spinner from "../../shared/Spinner";
import { useState } from "react";
import noGood from "../../../assets/noGood.jpeg";

import { useParams, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { getContestChallenge } from "../../features/competition/competitionSlice";
import Confetti from "react-confetti";

Modal.setAppElement("#root");

function CompetitionChallenge() {
  const { challengeId } = useParams();

  const dispatch = useDispatch();
  const { challenge, showSpinner, generalError, contestCompleted, contest } =
    useSelector((state) => state.competitions);

  useEffect(() => {
    try {
      dispatch(getContestChallenge(challengeId)).unwrap();
    } catch (err) {
      console.log(err);
    }
  }, [challengeId, dispatch]);

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  //   Array.from(document.links)
  //     .filter((link) => link.hostname != window.location.hostname)
  //     .forEach((link) => (link.target = "_blank"));

  if (generalError) {
    return (
      <div className="h-screen flex items-center justify-center bg-zinc-950 ">
        <div className="text-center text-xl bg-zinc-800 bord border-black px-32 py-24">
          <p className="">{generalError.Error}</p>
          {generalError.ShowIMG && (
            <img src={noGood} alt="fireSpot" className="h-64 py-4" />
          )}
          {generalError.FixRoute && (
            <>
              <Link
                to={generalError.FixRoute}
                target="_blank"
                className="text-base-300 underline hover:text-yellow-500"
              >
                {generalError?.FixLabel}
              </Link>
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <>
      {showSpinner && <Spinner />}
      {contestCompleted && (
        <>
          <Confetti width={width} height={height} />
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50  backdrop-blur-[2px] backdrop-brightness-20">
            <div className="text-sm text-white bg-zinc-900 py-14 px-32 border-white border">
              <p className="pb-2">
                <strong className="text-yellow-400">
                  {contestCompleted.name} - {contestCompleted.type}
                </strong>{" "}
              </p>
              <p className="pb-2">
                Way to go, {contestCompleted.displayName}! You finished in{" "}
                <strong className="text-yellow-400">
                  {contestCompleted.placement}
                </strong>{" "}
                place.
              </p>
              <div className="pt-7 pb-3 font-semibold">
                <p>Stats:</p>
              </div>
              <p className="pb-2">
                Challenges Completed:{" "}
                <strong className="text-yellow-400">
                  {contestCompleted.totalChallenges}
                </strong>
              </p>
              <p className="pb-2">
                Surpassed Time:{" "}
                <strong className="text-yellow-400">
                  {contestCompleted.surpassedTime}
                </strong>
              </p>
              <p className="pb-2">
                Points Earned:{" "}
                <strong className="text-yellow-400">
                  {contestCompleted.totalPointsEarned}
                </strong>
              </p>
              {contestCompleted.bonusPoints && (
                <p className="pb-2">
                  Placement Bonus Points:{" "}
                  <strong className="text-yellow-400">
                    {contestCompleted.bonusPoints}
                  </strong>
                </p>
              )}
              {contestCompleted.bonusPoints && (
                <p className="pb-2">
                  Total Points:{" "}
                  <strong className="text-yellow-400">
                    {contestCompleted.bonusPoints +
                      contestCompleted.totalPointsEarned}
                  </strong>
                </p>
              )}
              <div className="flex items-center justify-center pt-5">
                <Link
                  to={"/compete/" + contestCompleted.tag}
                  className="btn btn-wide btn-sm btn-primary px-48 whitespace-nowrap"
                >
                  See All Results
                </Link>
              </div>
            </div>
          </div>
        </>
      )}

      <div>
        <Split className="splitHor max-h-72" minSize={600} gutterSize={8}>
          <CompetitionMarkdown challenge={challenge} />

          <Split
            direction="vertical"
            minSize={[300, 200]}
            gutterSize={8}
            snapOffset={1}
            dragInterval={1}
          >
            <div className="block">
              {challenge?.[0] && (
                <CodeEditor key={challenge[0]._id} challenge={challenge[0]} />
              )}
            </div>
            <div className="pl-4 bg-zinc-900 text-white py-2 overflow-y-scroll">
              <ResultPanel />
            </div>
          </Split>
        </Split>

        {challenge && <CompetitionFooter challenge={challenge} />}
      </div>
    </>
  );
}

export default CompetitionChallenge;
