import React, { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment-timezone";
import moment2 from "moment";

function ContestantResults() {
  const { contest } = useSelector((state) => state.competitions);
  const contestId = contest?.contest?._id;

  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  let updatedContest = contest?.contestLeaderboard?.map((item) => {
    // Convert the startdate to the user's timezone and format it
    if (item.endTime) {
      const userDateTime = moment
        .utc(item.endTime)
        .tz(timeZone)
        .format("hh:mm A");
      return {
        ...item,
        endTimeOnly: userDateTime,
      };
    }

    return {
      ...item,
    };
  });

  updatedContest?.forEach((item) => {
    let start = moment2(item.startTime);
    let end = moment2(item.endTime);

    const diff = item.endTime - item.startTime;
    console.log(diff);
    console.log(item.endTime);
    console.log(item.startTime);

    const duration = moment2.duration(end.diff(start));
    let h = duration.hours();
    let m = duration.minutes();
    let s = duration.seconds();

    item.totalTime =
      h == 0
        ? `${m} minutes, ${s} seconds`
        : `${h} hours, ${m} minutes, ${s} seconds`;
  });

  console.error(updatedContest);

  return (
    <div className="col-span-2 max-h-80 overflow-auto px-5">
      {updatedContest && (
        <table className="table table-pin-rows table-pin-cols w-full">
          <thead className="">
            <tr>
              <th className="sticky top-0  bg-zinc-800">Position</th>
              <th className="sticky top-0  bg-zinc-800">Display Name</th>
              <th className="sticky top-0  bg-zinc-800">Status</th>
              <th className="sticky top-0  bg-zinc-800">End Time</th>
              <th className="sticky top-0  bg-zinc-800">Time Spent</th>
            </tr>
          </thead>
          <tbody className="">
            {updatedContest.map((item, index) => (
              <tr key={index}>
                <td className="text-xs md:text-sm bg-zinc-900">{index + 1}</td>
                <td className="text-xs md:text-sm bg-zinc-900">
                  {item.displayName}
                </td>
                <td className="text-xs md:text-sm bg-zinc-900">
                  {item.status}
                </td>
                <td className=" text-xs md:text-sm bg-zinc-900">
                  {item.endTimeOnly}
                </td>
                <td className=" text-xs md:text-sm bg-zinc-900">
                  {item.totalTime}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default ContestantResults;

/*
- Get the competition ID
- Find all "started" particpants
- Find all "Finsihed" particpants and thier position
- combine these into 1
- return the results
- pass to datatable
*/
