import { Link } from "react-router-dom";

function LessonNav({ lesson }) {
  const isPractice = lesson[0]?.collectionDetails.type === "practice";
  return (
    <div className="grid grid-flow-col bg-zinc-900 py-3">
      <Link
        to={
          isPractice
            ? `/practice/${lesson[0]?.collectionDetails.slug.toLowerCase()}/${lesson[0].courseDetails.name.toLowerCase()}`
            : `/course/${lesson[0]?.courseDetails.slug}`
        }
        className="bg-[#242424] pt-1 col-span-3 text-center text-neutral-300 hover:underline hover:text-purple-300"
      >
        {lesson[0]?.courseDetails?.name}
      </Link>
      <div className="border-[#242424] border-2 border-r-0">
        <div className="mt-1.5 border-[#242424] border-t-[.5em] border-t-transparent border-t-solid border-l-[.75em] border-l-solid border-b-[.5em] border-b-solid border-b-transparent inline-block"></div>
      </div>

      {!isPractice ? (
        <div className="text-left border-[#242424] border-2 border-l-0 pt-1">
          {lesson[0]?.collectionName}
        </div>
      ) : (
        <div className="text-left border-[#242424] border-2 border-l-0 pt-1 hover:underline hover:text-purple-300">
          <Link
            to={`/practice/${lesson[0]?.collectionDetails.slug.toLowerCase()}`}
          >
            {lesson[0]?.collectionName}
          </Link>
        </div>
      )}
    </div>
  );
}

export default LessonNav;
