const Header = ({
  initialRole,
  selectedRole,
  handleRoleChange,
  practiceData,
}) => {
  const roles =
    practiceData.data != null ? practiceData.data.map((item) => item.slug) : [];

  return (
    <header className="flex flex-col items-center my-12">
      <h1 className="text-4xl font-bold text-white mb-2">
        Become the best{" "}
        <div className="dropdown dropdown-hover inline-block relative">
          <label
            tabIndex={0}
            className="cursor-pointer text-purple-300 text-4xl flex items-center"
          >
            {initialRole === "{Your Role}" ? (
              <span className="text-gray-400">{initialRole}</span>
            ) : (
              selectedRole
            )}
            <svg
              className="ml-1 h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </label>
          <ul
            tabIndex={0}
            className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52 text-base"
          >
            {roles.map((role) => (
              <li key={role}>
                <a
                  href="#"
                  onClick={() => handleRoleChange(role)}
                  className={`${
                    selectedRole === role ? "text-purple-300" : ""
                  }`}
                >
                  {role}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </h1>
      <p className="text-lg text-gray-400">the hard way. Made easy.</p>
    </header>
  );
};

export default Header;
