import SalesforceLogin from "./SalesforceLogin";
import SalesforceLogout from "./SalesforceLogout";
import { getUser } from "../../features/auth/authSlice";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

function Auth() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  const { user } = useSelector((state) => state.auth);
  return (
    <div className="bg-base">
      {/* {user} */}
      <div className="bg-zinc-900">
        {/* hero min-h-screen  */}
        <div className="">
          {" "}
          {/* //flex flex-col justify-center items-center */}
          {user?.length ? (
            <SalesforceLogout user={user[0].instanceUrl} />
          ) : (
            <SalesforceLogin />
          )}
        </div>
      </div>
    </div>
  );
}

export default Auth;
