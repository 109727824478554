import React, { useCallback } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { useSelector, useDispatch } from "react-redux";
import { getLessonsForCourse } from "../../features/courses/courseSlice";
import { useNavigate } from "react-router-dom";
import Spinner from "../../shared/Spinner";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { Helmet } from "react-helmet";

function LessonList() {
  const { lessonList, courseDetails, showSpinner } = useSelector(
    (state) => state.courses
  );
  const dispatch = useDispatch();
  const { slug } = useParams();

  useEffect(() => {
    try {
      dispatch(getLessonsForCourse(slug)).unwrap();
    } catch (err) {
      console.log(err);
    }
  }, [slug, dispatch]);

  let descriptionHeader = courseDetails.isProject ? "" : "Description";
  const hasAuthorDetails = lessonList.some((lesson) => lesson.authorDetails);

  descriptionHeader = hasAuthorDetails ? "Author" : descriptionHeader;

  const navigate = useNavigate();
  const go = useCallback(
    (lessonId) => navigate(`/lesson/${lessonId}`),
    [navigate]
  );
  return (
    <div className="min-h-screen cursor-default bg-zinc-900">
      <Helmet>
        <title>
          {courseDetails.isProject
            ? `${courseDetails.name} Project & Tasks | Camp Apex`
            : `${courseDetails.name} Course & Lessons | Camp Apex`}
        </title>
        <meta
          name="description"
          content={
            courseDetails.isProject
              ? `Explore the tasks for the ${courseDetails.name} project at Camp Apex. Learn Apex development by building real projects with hands-on tasks.`
              : `Explore the lessons for the ${courseDetails.name} course at Camp Apex. Learn Apex development through detailed lessons and track your progress.`
          }
        />
        <meta
          property="og:title"
          content={
            courseDetails.isProject
              ? `${courseDetails.name} Project & Tasks | Camp Apex`
              : `${courseDetails.name} Course & Lessons | Camp Apex`
          }
        />
        <meta
          property="og:description"
          content={
            courseDetails.isProject
              ? `Access a comprehensive list of tasks for the ${courseDetails.name} project. Enhance your Salesforce Apex skills by building and completing real-world tasks.`
              : `Access a comprehensive list of lessons for the ${courseDetails.name} course. Enhance your Salesforce Apex skills with hands-on practice and real-world examples.`
          }
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`https://campapex.org${window.location.pathname}`}
        />
        <meta
          property="og:image"
          content="https://campapex.org/assets/hero6.png"
        />
        <link
          rel="canonical"
          href={`https://campapex.org${window.location.pathname}`}
        />
      </Helmet>

      {showSpinner ? (
        <Spinner />
      ) : (
        <>
          <h1 className="text-center lg:text-2xl text-xl font-bold pt-14 pb-7 text-white">
            {courseDetails.name}
          </h1>
          <h1 className="text-center lg:text-2xl text-xl font-bold  pb-7 text-white">
            {courseDetails.author1 && (
              <p className="text-xs lg:text-lg  pt-2 pb-1">
                Author:{" "}
                {courseDetails.author1Link ? (
                  <a
                    href={courseDetails.author1Link}
                    target="_blank"
                    rel="noopener noreferrer	 "
                  >
                    <span className="hover:underline  text-purple-500">
                      {courseDetails.author1}
                    </span>
                  </a>
                ) : (
                  courseDetails.author1
                )}
              </p>
            )}
          </h1>

          <ReactMarkdown
            className="text-center text-sm lg:text-base pb-4 px-6 text-white lg:px-72"
            children={courseDetails.description}
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
          />
          <br />
          <div className="overflow-x-auto flex flex-col justify-center lg:items-center pb-36 text-xs lg:text-sm text-white font-light ">
            <table className="table w-7/12">
              <thead className="border-b">
                <tr>
                  <th className="bg-zinc-800 	">#</th>
                  <th className="bg-zinc-800 	">Name</th>
                  <th className="bg-zinc-800 	">{descriptionHeader}</th>
                  <th className="bg-zinc-800 	">Status</th>
                </tr>
              </thead>
              {lessonList.map((lesson, index) => (
                <tbody key={index} className="dov">
                  <tr
                    className=""
                    onClick={() => {
                      go(lesson._id);
                    }}
                  >
                    {index % 2 === 0 && (
                      <th className="bg-zinc-900 font-light">
                        {lesson.order + 1}
                      </th>
                    )}
                    {index % 2 !== 0 && (
                      <th className="bg-zinc-800 	 font-light">
                        {lesson.order + 1}
                      </th>
                    )}
                    {index % 2 === 0 && (
                      <th className="bg-zinc-900 font-light">{lesson.name}</th>
                    )}
                    {index % 2 !== 0 && (
                      <th className="bg-zinc-800 	  font-light">
                        {lesson.name}
                      </th>
                    )}
                    {index % 2 === 0 && (
                      <th className="bg-zinc-900 font-light">
                        {lesson.description ||
                          (lesson.authorDetails ? (
                            <a
                              href={lesson.authorDetails.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="hover:underline text-purple-300">
                                {lesson.authorDetails.name}
                              </span>
                            </a>
                          ) : null)}
                      </th>
                    )}
                    {index % 2 !== 0 && (
                      <th className="bg-zinc-800 	 font-light">
                        {lesson.description ||
                          (lesson.authorDetails ? (
                            <a
                              href={lesson.authorDetails.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="hover:underline text-purple-300">
                                {lesson.authorDetails.name}
                              </span>
                            </a>
                          ) : null)}
                      </th>
                    )}
                    {index % 2 === 0 && (
                      <td className="bg-zinc-900 font-light">
                        {" "}
                        {lesson?.progress[0]?.status === "Completed" && (
                          <span className="indicator-item badge badge-secondary text-xs lg:text-xs">
                            {lesson?.progress[0]?.status}
                          </span>
                        )}
                        {lesson?.progress[0]?.status === "In Progress" && (
                          <span className="indicator-item badge badge-warning text-xs lg:text-xs">
                            {lesson?.progress[0]?.status}
                          </span>
                        )}
                        {(!lesson?.progress[0]?.status ||
                          lesson?.progress[0]?.status === "Not Started") && (
                          <span className="indicator-item badge badge-error text-xs  lg:text-xs">
                            {"Not Started"}
                          </span>
                        )}
                      </td>
                    )}
                    {index % 2 !== 0 && (
                      <td className="bg-zinc-800">
                        {" "}
                        {lesson?.progress[0]?.status === "Completed" && (
                          <span className="indicator-item badge badge-secondary text-xs lg:text-xs">
                            {lesson?.progress[0]?.status}
                          </span>
                        )}
                        {lesson?.progress[0]?.status === "In Progress" && (
                          <span className="indicator-item badge badge-warning text-xs lg:text-xs">
                            {lesson?.progress[0]?.status}
                          </span>
                        )}
                        {(!lesson?.progress[0]?.status ||
                          lesson?.progress[0]?.status === "Not Started") && (
                          <span className="indicator-item badge badge-error text-xs  lg:text-xs">
                            {"Not Started"}
                          </span>
                        )}
                      </td>
                    )}
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
        </>
      )}
    </div>
  );
}

export default LessonList;
