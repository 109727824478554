import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import { useEffect } from "react";
import LessonStatus from "./LessonStatus";
import LessonChallenge from "./LessonChallenge";
import InstallPackage from "./InstallPackage";
import { useSelector } from "react-redux";

function LessonMarkdown({ lesson }) {
  useEffect(() => {
    if (lesson[0]?.progress[0]?.status !== "In Progress") {
      var lessonPanelDiv = document.getElementById("lesson-panel");
      lessonPanelDiv.scrollTop = 0;
    }
  }, [lesson]);

  const { user } = useSelector((state) => state.auth);
  let lessonBody = lesson[0]?.lesson;
  let challengeBody = lesson[0]?.challenge;
  return (
    <>
      {challengeBody ? (
        <div
          className="bg-zinc-900 text-slate-100 px-6 overflow-y-auto"
          id="lesson-panel"
        >
          <LessonStatus lesson={lesson} />
          <ReactMarkdown
            children={lessonBody ? lessonBody : challengeBody}
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
            components={{
              code({ node, inline, className, children, ...props }) {
                const match = /language-(\w+)/.exec(className || "");
                return !inline && match ? (
                  <SyntaxHighlighter
                    children={String(children).replace(/\n$/, "")}
                    style={vscDarkPlus}
                    language={match[1]}
                    PreTag="div"
                    {...props}
                  />
                ) : (
                  <code className={className} {...props}>
                    {children}
                  </code>
                );
              },
            }}
          />
          {lessonBody ? (
            <LessonChallenge challengeBody={challengeBody} />
          ) : (
            <></>
          )}
        </div>
      ) : (
        <div className="lg:max-h-screen ">
          <div
            className="lg:px-64 bg-zinc-900 text-slate-100 grid grid-cols-1 "
            id="lesson-panel"
          >
            <h2 className="font-semibold text-lg text-center pt-4 pb-8">
              {lesson[0]?.name}

              {lesson[0]?.progress[0]?.status && (
                <div className="">
                  <span className="indicator-item badge badge-success">
                    Completed
                  </span>
                </div>
              )}
              {!lesson[0]?.progress[0]?.status && (
                <div className="">
                  <span className="indicator-item badge badge-error">
                    Reading Only
                  </span>
                </div>
              )}
            </h2>

            <ReactMarkdown
              children={lessonBody}
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeRaw]}
              components={{
                code({ node, inline, className, children, ...props }) {
                  const match = /language-(\w+)/.exec(className || "");
                  return !inline && match ? (
                    <SyntaxHighlighter
                      children={String(children).replace(/\n$/, "")}
                      style={vscDarkPlus}
                      language={match[1]}
                      PreTag="div"
                      {...props}
                    />
                  ) : (
                    <code className={className} {...props}>
                      {children}
                    </code>
                  );
                },
              }}
            />
          </div>
          <InstallPackage user={user} lesson={lesson} />
        </div>
      )}
    </>
  );
}

export default LessonMarkdown;
