import React from "react";
import { Link, useNavigate } from "react-router-dom";
import hero from "../../assets/hero6.png";
import feedback from "../../assets/Feedback.png";
import { FaTools, FaCode } from "react-icons/fa";
import { GiMailedFist } from "react-icons/gi";
import Card from "../HomePageCard";
import MiniEditor from "../shared/MiniEditor";
import { Helmet } from "react-helmet";
import { FaArrowRight } from "react-icons/fa";

function HomePage() {
  const navigate = useNavigate();

  const reRoute = (path) => {
    navigate(path);
  };

  return (
    <div className="overflow-y-auto ">
      <Helmet>
        <title>
          Camp Apex | Interactive Lessons and Challenges for Trailblazers
        </title>
        <meta
          name="description"
          content="Camp Apex offers hands-on Salesforce development practice with interactive lessons and challenges."
        />
        <meta
          property="og:title"
          content="Camp Apex | Interactive Lessons and Challenges"
        />
        <meta
          property="og:description"
          content="Get hands-on experience with Salesforce development through free interactive lessons and challenges."
        />
        <meta property="og:image" content={hero} />
        <meta property="og:url" content="https://www.campapex.org" />
        <link rel="canonical" href="https://www.campapex.org/" />
      </Helmet>

      <div className="bg-[#011c29] !bg-zinc-900 ">
        <div className="hero xl:min-h-[70vh] min-h-[40vh]">
          <div className="hero-content flex-col lg:flex-row-reverse px-6 sm:px-32 ">
            <img
              src={hero}
              className="max-w-xs sm:max-w-sm xl:ml-8"
              alt="coding"
            />
            <div className="lg:pl-0 text-center !lg:text-left">
              <h1 className="text-xl sm:text-3xl xl:text-3xl font-bold text-grey-200 pb-9">
                Interactive Lessons and Challenges for Trailblazers
              </h1>
              <Link
                className="button btn-lg px-5 py-2 sm:px-11 sm:py-3 btn-circle hover:bg-[#EDF5B5] bg-[#EDF5E1] text-zinc-900  "
                to="/auth"
              >
                Get Started. It's FREE
                <FaArrowRight className="ml-2 inline text-sm" />
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center items-center w-full">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full px-5 sm:px-9 xl:px-24">
          <div className="space-y-4 col-span-2 lg:-mt-9">
            <div className="bg-zinc-900 rounded-lg text-center">
              <div className="w-full px-9 text-xs sm:text-base text-center">
                <div className="grid lg:grid-cols-3 grid-cols-1 lg:gap-10 pb-8 2xl:pb-32 lg:px-24">
                  <Card
                    primary="1. Login with Salesforce"
                    secondary="Connect to a Trailhead org"
                  />
                  <Card
                    primary="2. Explore"
                    secondary="Find a topic that meets your learning goals"
                  />
                  <Card
                    primary="3. Pop off on 'em!"
                    secondary="Write real code to get real experience"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="space-y-4 col-span-2 lg:px-16">
            <div className="bg-zinc-800 p-8 rounded-lg text-white ">
              <div className="flex flex-col lg:flex-row-reverse">
                <div className="h-96 lg:w-full xl:pr-9 hidden lg:block">
                  <MiniEditor />
                </div>
                <div className="flex flex-col items-center justify-center 2xl:pl-16 2xl:pr-24 md:px-7">
                  <h2 className="xl:text-2xl text-center xl:text-left text-lg text-white text-left self-start w-full pb-3">
                    Hands on Practice
                  </h2>
                  <div className="text-white text-xs xl:text-lg">
                    Use the built-in Code Editor to write Apex. Don't worry
                    about setting up VSCode or fumbling around the Developer
                    Console. Focus on the code.
                    <br />
                    <br />
                    New to Salesforce development? We got you. A seasoned pro?
                    We'll see about that.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="space-y-4 col-span-2 xl:col-span-1 row-span-1 xl:row-span-2 2xl:pl-32 text-center bg-zinc-800 p-4 xl:p-8 rounded-lg text-white lg:ml-16 lg:mr-16 xl:mr-0">
            <h2 className="xl:text-2xl text-lg text-white text-left self-start w-full pb-3 text-center">
              Get Instant Feedback
            </h2>
            <div className="text-xs xl:text-lg">
              Camp Apex runs a suite of tests to verify your work. From Apex to
              SOQL, we'll let you know how you did.
              <div className="flex items-center justify-center pt-4">
                <img
                  src={feedback}
                  className="lg:max-w-sm sm:max-w-xs"
                  alt="feedback"
                />
              </div>
            </div>
          </div>

          <div className="space-y-4 col-span-2 lg:col-span-1 2xl:px-32 text-center p-8 rounded-lg text-white bg-zinc-800 md:ml-16 xl:ml-0 xl:mr-16">
            {/* <h2 className="xl:text-2xl text-lg text-white text-left self-start w-full  pb-3 text-center">
              Join the Community
            </h2>
            <div className="text-xs xl:text-lg">
              Over Slack, Zoom, and In-Person. Lend your expertise or get help
              from other Trailblazers, make friends, and grow your network.
            </div> */}
            <h2 className="xl:text-2xl text-lg text-white text-left self-start w-full text-center pt-12">
              <del style={{ textDecorationThickness: "2px" }}>
                Learn Java, then learn Apex
              </del>
            </h2>
            <div className="text-xs xl:text-lg">
              Skip the detour. Learn Apex and its nuance from the start.
            </div>
          </div>

          <div className="space-y-4 col-span-2  lg:col-span-1 2xl:px-32 text-center p-8 rounded-lg text-white bg-zinc-800 lg:mr-16">
            <h2 className="xl:text-2xl text-lg text-white text-left w-full pb-3 text-center pt-7">
              Build Your Confidence
            </h2>
            <div className="text-xs xl:text-lg">
              Go at a pace that works for you. Don't just watch someone else do
              it - do it for yourself. It's you writing that code. It's real
              experience.
            </div>
          </div>

          {/* <div className="space-y-4 col-span-2 row-span-1 lg:px-16 text-center">
            <div className="bg-zinc-800 p-8 rounded-lg text-white"></div>
          </div> */}

          <div className="space-y-4 col-span-2 row-span-1 lg:px-16 text-center">
            <div className="bg-zinc-800 p-8 rounded-lg text-white">
              <h2 className="xl:text-2xl text-lg text-white text-left self-start w-full pb-3 text-center">
                This must cost a fortune!
              </h2>
              <div className="text-xs xl:text-lg">
                Nope! It's 100% free. I got you.
                <br />
                You can{" "}
                <a
                  className="text-md align-left text-primary hover:underline"
                  href="https://www.buymeacoffee.com/CampApexDotOrg"
                  target="_blank"
                >
                  donate
                </a>{" "}
                to help cover server costs.
              </div>
            </div>
          </div>

          <div className="space-y-4 col-span-2 lg:px-16 pt-16 pb-32">
            <div className="text-4xl font-bold text-center pb-7 text-yellow-300">
              Let's Get Going.
              <br />
              Pick a Place to Start.
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 text-base ">
              <div className="">
                <CategoryCard
                  icon={
                    <FaCode className="text-4xl xl:text-6xl mb-7 " size={32} />
                  }
                  title="Lessons"
                  description="Apex, SOQL, & OOP. <br/><br/> Bite-sized lessons covering the fundamentals. Each paired with a challenge to solve."
                  onClick={() => reRoute("/courses")}
                />
              </div>
              <div className="">
                <CategoryCard
                  icon={
                    <FaTools className="text-4xl text-6xl mb-7" size={32} />
                  }
                  title="Projects"
                  description="Build a custom Salesforce application from scratch. Camp Apex is your product manager and QA team. <br/><br/> Implement the project's user stories. Will you choose clicks or code?"
                  onClick={() => reRoute("/projects")}
                />
              </div>
              <div className="">
                <CategoryCard
                  icon={<GiMailedFist className="text-4xl mb-7" size={32} />}
                  title="Competitions"
                  description="Think you're a pretty good developer? Prove it. <br/><br/>Time is ticking, compete against the world for a place on the Global Top 25. Competitions run monthly.<br/><br/>"
                  onClick={() => reRoute("/compete")}
                />
              </div>
              {/* <div className="">
                <CategoryCard
                  icon={
                    <FaPeopleCarry
                      className="text-4xl text-6xl mb-7"
                      size={32}
                    />
                  }
                  title="Events"
                  description="Virtual and In-Person Build Nights. <br/><br/> Meet up with other aspiring developers and build apps together."
                  onClick={() => reRoute("/events")}
                />
              </div> */}
              {/* <div className="">
                <CategoryCard
                  icon={
                    <FaPeopleCarry
                      className="text-4xl text-6xl mb-7"
                      size={32}
                    />
                  }
                  title="Practice"
                  description="Keep your skills sharp and prep for interviews. <br/><br/> 
                  Built for developers and admins, tackle a series of job relevant practice problems."
                  onClick={() => reRoute("/practice")}
                />
              </div> */}
            </div>
          </div>

          {/* <div className="space-y-4 2xl:px-32 text-center bg-zinc-800 p-8 rounded-lg text-white col-span-2 my-12 lg:mx-16 ">
            <FeedbackCarousel />
          </div> */}
        </div>
      </div>
    </div>
  );
}

function CategoryCard({ icon, title, description, onClick }) {
  return (
    <div
      className="bg-zinc-800 p-6 shadow-md text-zinc-100 home-category h-full"
      onClick={onClick}
    >
      <div className="flex flex-cols ">
        {icon}
        <h3 className="text-lg xl:text-xl font-semibold mb-7 pl-2 text-white">
          {title}
        </h3>
      </div>
      <p
        className="text-md xl:text-md"
        dangerouslySetInnerHTML={{ __html: description }}
      ></p>
    </div>
  );
}

export default HomePage;
