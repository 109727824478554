import { Helmet } from "react-helmet";

const SectionHeader = ({ children }) => (
  <h2 className="text-2xl font-semibold text-gray-100 mb-4">{children}</h2>
);

const SectionContent = ({ children }) => (
  <p className="text-gray-300 mb-4">{children}</p>
);

const Column = ({ title, children }) => (
  <div className="flex flex-col p-8  rounded-lg md:w-1/2">
    <SectionHeader>{title}</SectionHeader>
    {children}
  </div>
);

const About = () => {
  return (
    <div className="flex flex-col md:flex-row md:space-x-8 p-8 text-white min-h-screen w-full bg-zinc-900">
      <Helmet>
        <title>About Camp Apex | Learn Apex with Hands-On Practice</title>
        <meta
          name="description"
          content="Discover why Camp Apex was built and how it helps aspiring Salesforce developers learn Apex through hands-on practice and real-world challenges."
        />
        <meta
          property="og:title"
          content="About Camp Apex | Learn Apex with Hands-On Practice"
        />
        <meta
          property="og:description"
          content="Learn about the creation of Camp Apex, a platform designed for hands-on Apex learning with real coding challenges. Discover the technology stack and the story behind it."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://campapex.org/about" />
        <meta
          property="og:image"
          content="https://campapex.org/assets/hero6.png"
        />
        <link rel="canonical" href="https://campapex.org/about" />
      </Helmet>
      <Column title="Why I built this">
        <SectionContent>
          I believe the most authentic approach to becoming a developer is by
          getting hands-on with a keyboard and writing actual code. If you're
          investing your time to learn a new skill, I'd bet your time is better
          spent learning from experience. Writing real code as you learn is the
          way to do that.
        </SectionContent>
        <SectionContent>
          With Camp Apex, coding lessons have been divded into manageable,
          bite-sized sections, each paired with an Apex coding challenge to
          solve within the site. These challenges are designed to reinforce your
          learning and progressively build upon one another. If I were to go
          back in time and learn Apex from scratch, this would be my preferred
          learning method.
        </SectionContent>
        <SectionContent>
          I keep saying "I", but I haven't introduced myself, I'm{" "}
          <a
            className="text-primary hover:underline"
            href="https://samanattar.com"
            target="_blank"
          >
            Saman
          </a>
          . I built this site in 2022 and released it in 2023. It took a lot
          longer than I thought to build and producing the content has taken
          even longer. But, I've learned a lot and making this site has brought
          me a lot of happiness and fulfilment. I hope it can get you to where
          you want to be. Thanks for giving the site a try and let me know if it
          falls short.
        </SectionContent>
      </Column>

      <Column title="How I built this">
        <SectionContent>
          When you connect your SFDC instance to Camp Apex, a composite key of
          your org's Id and user's 18-digit Id is kept. This is used to track &
          retain your progress in Camp Apex when you log back in next time.
        </SectionContent>
        <SectionContent>
          This is a MERN (MongoDB, Express, React (w/ Redux), NodeJS) stack app.
          A secure OAuth2 connection with your SFDC org is established when you
          login. This connection allows us to compile and execute your code
          submission along with our pre-written test class within your
          Salesforce org. The results are returned back here, where you're told
          how you did.
        </SectionContent>
        <SectionContent>
          The UI was built with Tailwind and DaisyUI. The code editor is Monaco
          Editor, the same editor that powers VS Code. I use Redis for caching
          and MongoDB for the data store. With the exception of MongoDB,
          everything is hosted on Heroku.
        </SectionContent>
        <SectionContent>
          The site is free and the best way to support is by using it, sharing
          it, and providing feedback. If you're interested and have the means to
          do so, you can also contribute towards the site's monthly bill by{" "}
          <a
            className="text-primary hover:underline"
            href="https://www.buymeacoffee.com/CampApexDotOrg"
            target="_blank"
          >
            donating here
          </a>
          .
        </SectionContent>
      </Column>
    </div>
  );
};

export default About;
