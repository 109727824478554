import { useState, useEffect } from "react";

function useCountdown(targetUtcDate) {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (isActive) {
      return;
    }
    if (!targetUtcDate) {
      console.log("No targetUtcDate provided");
      return;
    }

    const targetDate = new Date(targetUtcDate).getTime();

    if (isNaN(targetDate)) {
      return;
    }

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = targetDate - now;

      if (distance <= 0 && !isActive) {
        clearInterval(interval);
        setIsActive(true);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [targetUtcDate, isActive]);

  return isActive;
}

export default useCountdown;
