import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setOpenCollection } from "../../features/courses/courseSlice";

function CollectionItem({ collection }) {
  const { openCollection } = useSelector((state) => state.courses);

  const dispatch = useDispatch();

  return (
    <>
      <a
        className={
          openCollection?.name === collection?.name
            ? "tab tab-bordered tab-active lg:text-lg text-xs !text-white"
            : "tab tab-bordered lg:text-lg text-xs "
        }
        onClick={(e) => {
          e.preventDefault();
          dispatch(setOpenCollection(collection));
        }}
        data-toggle="tab"
        href={"#" + collection.name}
        role="tablist"
      >
        {collection?.name}
      </a>
    </>
  );
}

export default CollectionItem;
