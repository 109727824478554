import React from "react";

const RoleSelector = ({ matchedData, selectedCourse, setSelectedCourse }) => {
  return (
    <div className="flex justify-center mb-8 space-x-4">
      {matchedData &&
        matchedData.courses
          .sort((a, b) => a.courseName.localeCompare(b.courseName))
          .map((course, index) => (
            <span
              key={index}
              className="cursor-pointer flex items-center space-x-2"
              onClick={() => setSelectedCourse(course.courseName)}
            >
              <span
                className={`${
                  selectedCourse?.toLowerCase() ===
                  course.courseName?.toLowerCase()
                    ? "text-purple-300 underline"
                    : "text-gray-400 hover:text-purple-300"
                }`}
              >
                {course.courseName}
              </span>
              <span className="badge bg-gray-600">
                {course.challenges.length}
              </span>
            </span>
          ))}
    </div>
  );
};

export default RoleSelector;
