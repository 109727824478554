import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CompetitionCardHolder from "./CompetitionCardHolder";
import Leaderboard from "./Leaderboard";
import { getAllContests } from "../../features/competition/competitionSlice";

function CompeteHome() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllContests());
  }, [dispatch]);

  const { competitions } = useSelector((state) => state.competitions);

  if (isMobile) {
    return (
      <div className="overflow-y-scroll">
        <section className="bg-[#011c29] bg-zinc-900">
          <div className="hero bg-zinc-900">
            <div className="hero-content flex-col">
              <div className="text-center">
                <h2 className="neon text-center pt-9 text-3xl sm:text-4xl md:text-5xl ">
                  Competitions
                </h2>
                <p className="text-center text-sm sm:text-md pt-3">
                  Battle Royale style. Compete for global rankings (and bragging
                  rights).
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="flex justify-center items-center w-full pb-9">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4 w-full px-4 sm:px-9 xl:px-24">
            <div className="space-y-4 col-span-1 lg:col-span-5">
              <div className="bg-zinc-900 p-4 sm:p-8 rounded-lg">
                <div className="flex flex-col ">
                  <CompetitionCardHolder competitions={competitions} />
                </div>
              </div>
            </div>

            <div className="space-y-4 bg-zinc-800 px-4 sm:px-20 pt-8 sm:pt-16 pb-9 rounded-lg col-span-1 sm:col-span-2 lg:col-span-3 ">
              <h2 className="text-center pb-9 text-lg sm:text-xl">
                Show The World What You Got.
              </h2>
              <div className="text-sm sm:text-md flex items-center justify-center ">
                When the competition starts, you'll need to race against the
                clock to solve a series of Salesforce challenges...
                <br />
                <br />
                Sign up in advance. Good luck, and have fun!
              </div>
            </div>
            <div className="space-y-4 bg-zinc-800 p-4 sm:p-8 rounded-lg col-span-1 sm:col-span-2 overflow-y-scroll max-h-96">
              <p className="text-center">The Global Top 25</p>
              <Leaderboard />
            </div>

            <div className="space-y-4 bg-zinc-800 p-4 sm:p-8 rounded-lg text-center col-span-1 sm:col-span-2 lg:col-span-5">
              We're looking for sponsors, judges, and mentors.{" "}
              <a
                href="https://tally.so/r/mDzZVj"
                className="text-[#fed128] hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Reach out.
              </a>
            </div>
          </div>
        </section>
      </div>
    );
  }

  return (
    <div className="overflow-y-scroll">
      <section className="bg-[#011c29] !bg-zinc-900">
        <div className="hero">
          <div className="hero-content flex-col lg:flex-row-reverse">
            <div className="lg:pl-0 text-center !lg:text-left">
              <h2 className="neon text-center sm:pt-9 xl:pt-9 md:text-5xl">
                Competitions
              </h2>
              <p className="text-center text-md pt-3">
                Battle Royale style. Compete for global rankings (and bragging
                rights).
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="flex justify-center items-center w-full pb-9">
        <div className="grid grid-cols-5 gap-4 w-full px-9 xl:px-24">
          <div className="col-span-5 space-y-4">
            <div className="bg-zinc-900 p-8 rounded-lg">
              <div className="flex flex-col">
                <div className="flex justify-center">
                  <CompetitionCardHolder
                    competitions={competitions}
                    compStatus={["Hold", "Active"]}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="space-y-4 bg-zinc-800 xl:px-28 px-20 pt-16 pb-9 rounded-lg flex-1 col-span-3 ">
            <h2 className="text-center pb-9 text-xl">
              Show The World What You Got.
            </h2>
            <div className="text-md  flex items-center justify-center ">
              When the competition starts, you'll need to race against the clock
              to solve a series of Salesforce challenges, each falling into its
              category—like Apex Algorithms, SOQL, Apex Triggers, and
              Validations.
              <br />
              <br />
              You're given a set time to tackle these challenges quickly for a
              shot at the leaderboard. Each challenge racks up points, and
              placements bring bonus points. In the end, we tally the scores and
              crown a winner.
              <br />
              <br />
              Sign up in advance. Good luck, and have fun!
            </div>
          </div>
          <div className="space-y-4 bg-zinc-800 p-8 rounded-lg flex-1 col-span-2 ">
            <p className="text-center">The Global Top 25</p>
            <Leaderboard />
          </div>

          <div className="col-span-5 space-y-4 bg-zinc-800 p-8 rounded-lg text-center">
            We're looking for sponsors, judges, and mentors.{" "}
            <a
              href="https://tally.so/r/mDzZVj"
              className="text-[#fed128] hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Reach out.
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default CompeteHome;
