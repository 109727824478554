import React from "react";
import { Link } from "react-router-dom";
import ActionButton from "./ActionButton";

function LessonFooter({ lesson }) {
  let moduleType = "Lesson";
  if (
    lesson[0]?.editorlessChallenge ||
    lesson[0]?.collectionDetails.type === "practice"
  ) {
    moduleType = "Challenge";
  }

  return (
    <div className="navbar bg-black 2xl:h-[10vh]">
      <div className="flex-0">
        {lesson[0]?.last && (
          <div className="col-span-2">
            <Link
              to={`/lesson/${lesson[0].last}`}
              className="btn btn-sm btn-secondary lesson-btn-small lg:lessonBtn"
            >
              Last {moduleType}
            </Link>
          </div>
        )}
      </div>
      <div className="flex-1 pl-2">
        {lesson[0]?.next && (
          <div className="col-span-1">
            <Link
              to={`/lesson/${lesson[0].next}`}
              className="btn btn-sm btn-secondary lesson-btn-small lg:lessonBtn"
            >
              Next {moduleType}
            </Link>
          </div>
        )}
        {!lesson[0]?.next && lesson[0]?.nextCourse[0]?.slug && (
          <div className="col-span-1">
            <Link
              to={`/course/${lesson[0]?.nextCourse[0].slug}`}
              className="btn btn-sm btn-wide hover:bg-orange-900 bg-orange-800	btn-secondary lesson-btn-small lg:lessonBtn"
            >
              Next Course: {lesson[0]?.nextCourse[0].name}
            </Link>
          </div>
        )}
      </div>

      {lesson[0] && (
        <div className="navbar-start pl-2">
          <ActionButton />
        </div>
      )}
    </div>
  );
}

export default LessonFooter;
