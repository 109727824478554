import axios from "axios";
const API_URL = "https://www.campapex.org/api/auth/";
// const API_URL = "https://www.localhost:3000/api/auth/";
//const API_URL = process.env.AUTH_URL;

const login = async () => {
  let response;
  try {
    window.open(API_URL, "_self");
  } catch (e) {
    console.log("exception: ", e);
  }

  return response.data;
};

const customlogin = async (customURL) => {
  let response;
  try {
    window.open(API_URL + "?customURL=" + customURL + "", "_self");
  } catch (e) {
    console.log("exception: ", e);
  }

  return response.data;
};

const getUser = async () => {
  let response = await axios.get("/api/auth/getUser");
  return response.data;
};

// Logout user
const logout = async () => {
  try {
    let response = await axios.get("api/auth/logout");
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

const authService = {
  //   register,
  logout,
  login,
  customlogin,
  getUser,
};

export default authService;
