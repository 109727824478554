import { Link } from "react-router-dom";

function Contact() {
  return (
    <div className="hero h-[73vh]">
      <div className="text-center hero-content">
        <div className="max-w-lg">
          <h1 className="text-3xl font-bold">Contact</h1>
          <div className="font-normal pt-5">
            <p className="text-1xl mb-8">
              Any and all feedback is appreciated. Drop your thoughts{" "}
              <a
                className="text-sky-300"
                href="https://tally.so/r/mDzZVj"
                target="_blank"
              >
                here
              </a>{" "}
              or DM me on Twitter
              <a
                className="text-sky-300"
                href="https://twitter.com/SOQL101"
                target="_blank"
              >
                @SOQL101
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
