function HomePageCard({ title, primary, secondary }) {
  return (
    <div className="">
      <div className="card-body text-gray-300">
        <h4 className="text-md text-center font-bold ">{primary}</h4>
        <div className="border-t border-primary"></div>
        <p className="text-sm font-light text-center">{secondary}</p>
      </div>
    </div>
  );
}

export default HomePageCard;
