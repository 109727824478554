import React from "react";

function Contestant({
  contestant: { displayName, totalScore, totalCompleted },
  rank,
}) {
  return (
    <div className="bg-zinc-900 px-4 py-2 shadow flex items-center justify-between text-sm font-mono bg-zinc-800">
      <div className="flex items-center space-x-4">
        <span className="text-md font-bold text-white">{rank}</span>
        <div>
          <h3 className="text-purple-300 font-bold">{displayName}</h3>
          <div>
            <p className="text-gray-400">
              Score: <span className="font-semibold">{totalScore}</span>
            </p>
            <p className="text-gray-400">
              Completed: <span className="font-semibold">{totalCompleted}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contestant;
