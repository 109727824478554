import { useEffect, useState } from "react";
import { getGlobalLeaderboard } from "../../features/competition/competitionSlice";
import { useSelector, useDispatch } from "react-redux";

import Contestant from "./Contestant";

function Leaderboard() {
  const { globalLeaderboard } = useSelector((state) => state.competitions);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGlobalLeaderboard());
  }, [dispatch]);

  return (
    <div className="h-[26rem] overflow-y-scroll">
      {globalLeaderboard?.map((contestant) => {
        return (
          <div key={contestant._id}>
            <Contestant contestant={contestant} rank={contestant.rank} />
          </div>
        );
      })}
      {/* <p className="text-center text-red-200 text-sm pt-3 pb-4 bg-zinc-900">
        View All
      </p> */}
    </div>
  );
}

export default Leaderboard;
