import { useDispatch } from "react-redux";
import { login, customlogin } from "../../features/auth/authSlice";
import { useState } from "react";

import { Link } from "react-router-dom";

function SalesforceLogin() {
  const [customURL, setCustomURL] = useState("");
  const [urlError, setURLError] = useState("");

  const dispatch = useDispatch();

  function handleLogin() {
    dispatch(login());
  }

  function handleCustomLogin(event) {
    event.preventDefault();

    let error = true;
    if (!customURL) {
      setURLError("Please enter your Trailhead Org's URL");
    } else if (
      !customURL.includes("lightning") &&
      !customURL.includes("force")
    ) {
      setURLError("This doesn't look like a Salesforce org.");
    } else if (!customURL.includes("https://")) {
      setURLError("Please include 'https://' ");
    } else if (!(customURL.endsWith(".com") || customURL.endsWith(".com/"))) {
      setURLError(
        "Please only include the base URL. This should end with .com or .com/"
      );
    } else {
      error = false;
    }

    if (error) {
      const urlError = document.getElementById("urlError");
      const urlInput = document.getElementById("custom-url-input");
      urlInput.classList.add("visible");
      urlError.setAttribute("aria-hidden", false);
      urlError.setAttribute("aria-invalid", true);
    } else {
      dispatch(customlogin(customURL));
    }
  }

  const handleCustomURLChange = (event) => {
    setCustomURL(event.target.value);
  };

  return (
    <div className="h-full lg:h-screen flex flex-col justify-center items-center pt-14 lg:pt-0 ">
      <div className="cols-span-3 text-center">
        <h1 className="text-3xl font-bold">Authorize a Trailhead Org</h1>
      </div>

      <div className="flex flex-col lg:w-full lg:flex-row place-items-center">
        <div className="grid lg:h-20 flex-grow rounded-box place-items-center lg:w-6/12	lg:pr-12">
          <div className="text-center">
            <p className="text-xl font-normal text-center">
              Recent Org Connect
            </p>
          </div>
          <div className="text-center lg:pt-9 pt-2 text-sm lg:text-md">
            <div className="text-center lg:pb-8 h-20 pb-2">
              Connect the mostly recently opened Salesforce org
            </div>
            <button
              onClick={handleLogin}
              className="btn text-center px-48 bg-primary hover:bg-purple-500"
            >
              Connect
            </button>
          </div>
        </div>

        <div className="divider divider-vertical lg:divider-horizontal lg:before:h-24 lg:after:h-24 pt-24 pb-12 lg:pb-0">
          OR
        </div>

        <div className="grid lg:h-20 flex-grow rounded-box place-items-center lg:w-6/12	lg:pl-12">
          <div className="text-center">
            <p className="text-xl font-normal text-center">
              Specific Org Connect
            </p>
          </div>
          <div className="text-center pt-9">
            <form onSubmit={handleCustomLogin}>
              <div className="pb-9 h-20">
                <input
                  type="url"
                  placeholder="https://curious-goat-aifk5y-dev-ed.lightning.force.com"
                  className="input input-xs py-4	input-bordered w-full max-w-xl text-slate-900 input-white bg-white "
                  onChange={handleCustomURLChange}
                  value={customURL}
                  id="custom-url-input"
                />
                <span
                  role="alert"
                  id="urlError"
                  aria-hidden="true"
                  className="text-xs text-red-400"
                >
                  {urlError}
                </span>
              </div>

              <button
                type="submit"
                className="btn text-center px-48 bg-primary hover:bg-purple-500"
              >
                Connect
              </button>
            </form>
          </div>
        </div>
      </div>

      <div className="text-center pt-9 w-6/12">
        <div className="lg:pt-9">
          <div className="pt-9 pb-12">
            For a smooth experience, log into Salesforce before connecting to
            Camp Apex. Connecting establishes an OAuth2 connection with your
            Salesforce org. Find more technical details on the{" "}
            <Link className="text-primary" to="/about">
              About
            </Link>{" "}
            page.
          </div>
          <div>I hope this site helps your reach your goals.</div>
        </div>
      </div>
    </div>
  );
}

export default SalesforceLogin;
