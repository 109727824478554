import React from "react";
import { useParams } from "react-router-dom";
import { FaDownload } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getUser } from "../features/auth/authSlice";
import { installData } from "../features/courses/courseSlice";
import { useState, useEffect } from "react";
import Spinner from "../shared/Spinner";

function DataInstallation() {
  const { dataKey } = useParams();
  const { user } = useSelector((state) => state.auth);
  const { installDataResponse, showSpinner } = useSelector(
    (state) => state.courses
  );

  const dispatch = useDispatch();

  function handleInstall() {
    dispatch(getUser());
    dispatch(installData(dataKey));
  }

  return (
    <div className="hero">
      <div className="">{showSpinner && <Spinner />}</div>
      <div className="hero-content text-center">
        <div className="max-w-lg">
          <h1 className="text-3xl font-bold pt-24">Data Creation: {dataKey}</h1>
          {user[0]?.instanceUrl && installDataResponse == "" && (
            <>
              {" "}
              <p className="pt-32">
                {" "}
                This page will create sample data in your connected org:{" "}
                <a
                  className="underline"
                  href={user[0]?.instanceUrl}
                  target="_blank"
                >
                  {user[0]?.instanceUrl}
                </a>{" "}
              </p>
              <br />
              <p>
                If you'd prefer to preview the data first, it can be found{" "}
                <a
                  className="underline"
                  href="https://api.npoint.io/570159c024a1f6642b4f"
                  target="_blank"
                >
                  here
                </a>
                {"."} <br />
                <br />
              </p>
              <p>
                Confirm you're connected to your intended Salesforce Trailhead
                org before clicking the button below. You can delete this data
                at anytime.
              </p>
              <Link
                className="btn btn-lg	 btn-primary btn-wide my-20"
                to=""
                onClick={handleInstall}
              >
                <FaDownload className="" />
                Install Data
              </Link>
            </>
          )}
          {installDataResponse != "" && user[0]?.instanceUrl && (
            <p className="pt-44 pb-80">{installDataResponse}</p>
          )}
          {!user[0]?.instanceUrl && (
            <>
              {" "}
              <p className="pt-28 pb-96">
                {" "}
                Login to create sample data in your connected org.
              </p>
              <br />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default DataInstallation;
